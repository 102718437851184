import { 
  GET_USER,
  GET_USER_DIALER_SERVER,
  GET_USER_VB_TOKEN,
  GET_USER_ACCOUNTCODE,
  GET_USER_USERNAME,
  GET_HARMONY_API_URL,
  GET_USER_TAB_ID,
} from "../constants/getters";
import { 
  SET_USER,
} from "../constants/mutations";
export default {
  state: {
    user: {},
  },
  getters: {
    [GET_USER](state){ return state.user },
    [GET_USER_DIALER_SERVER](state,getters){ 
      if(process.env.NODE_ENV=='production') return getters[GET_USER].dialerServerVersion
      else return `${window.location.protocol}//${window.location.hostname}:8090`
    },
    [GET_USER_VB_TOKEN](state,getters){ return getters[GET_USER].vbToken },
    [GET_USER_ACCOUNTCODE](state,getters){ return getters[GET_USER].voipaccountcode },
    [GET_USER_USERNAME](state,getters){ return getters[GET_USER].username },
    [GET_HARMONY_API_URL](state,getters){ return getters[GET_USER].apiServer },
    [GET_USER_TAB_ID](state,getters){ return getters[GET_USER].tab_id },
  },
  actions: {
    
  },
  mutations: {
    [SET_USER](state,value){
      state.user=value ?? {}
    },
  },
};