import { routes_names } from '../constants/routes-names';
import { layout_names } from '../constants/layout-names';
import AuthorizationLayout from '@/layout/Authorization.vue';

export const routes = [
  {
    path: '/',
    name: layout_names.authorization,
    component: AuthorizationLayout,
    redirect: {
      name: routes_names.dashboard
    },
    children: [
      {
        path: '/login',
        name: routes_names.login,
        exact: true,
        component: () => import('@/view/Login.vue'),
        meta: {
          notRequiresAuth: true
        }
      },
      {
        path: '/',
        name: routes_names.dashboard,
        exact: true,
        component: () => import('@/view/Dashboard.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/signup',
        name: routes_names.signup,
        exact: true,
        component: () => import('@/view/Signup.vue'),
        meta: {
          notRequiresAuth: true
        }
      },
      {
        path: '/forget-password',
        name: routes_names.forget_password,
        exact: true,
        component: () => import('@/view/ForgetPassword.vue'),
        meta: {
          notRequiresAuth: true
        }
      },
      {
        path: '/meet',
        name: routes_names.conference,
        exact: true,
        component: () => import('@/view/Conference.vue'),
        meta: {
          
        }
      },
      {
        path: '/accept-ownership/:key',
        name: routes_names.accept_ownership,
        exact: true,
        component: () => import('@/view/AcceptOwnership.vue'),
        meta: {
          
        }
      },
      {
        path: '/invitation/:key',
        name: routes_names.invitation,
        exact: true,
        component: () => import('@/view/Invitation.vue'),
        meta: {
          
        }
      },
    ],
  },
]