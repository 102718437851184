import moment from 'moment'
import Timer from "timer.js";
export function apiInstance(message={}){
  const { 
    count, 
    source, 
    validation_errors, 
    response_error, 
    status, 
    upload_progress, 
    error_message, 
    send, 
    success_message,
    error_trigger,
    request_time,
    count_down,
  } = message
  let obj = {}
  if(count){
    obj.count = 0
    obj.increment = function(){ 
      obj.count += 1 
    }
  }
  if(source){
    obj.source = null
  }
  if(status){
    obj.status = 0
  }
  if(error_message){
    obj.error_message = ''
  }
  if(success_message){
    obj.success_message = ''
    obj.emptySuccessMessage = function (sec){
      setTimeout(()=>{
        obj.success_message = ''
      }, (sec || 1) * 1000)
    }
  }
  if(validation_errors){
    obj.validation_errors = {}
  }
  if(response_error){
    obj.error = {}
  }
  if(upload_progress){
    obj.upload_progress = {
      total: 0,
      loaded: 0,
      percentage: 0,
      is_process: false,
      onProgress(event){
        this.total=event.total
        this.loaded=event.loaded
        this.percentage=Math.floor((event.loaded / event.total) * 100)
        this.is_process=Math.floor((event.loaded / event.total) * 100) < 100
      },
      reset(){
        this.total=0
        this.loaded=0
        this.percentage=0
        this.is_process=false
      },
    }
  }
  if(error_trigger) {
    obj.error_trigger={
      show: false,
      trigger(cb,ms){
        let that = this
        this.show=true
        setTimeout(()=>{
          that.show=false
          cb?.()
        },ms || 1*1000)
      },
      reset(){
        this.show=false
      },
    }
  }
  if(request_time) {
    obj.request_time = {
      time: '',
      setTime(){
        this.time=moment().utc().format('DD MM YYYY hh:mm:ss')
      },
      compare(type='minutes',default_value=null){
        if(this.time) {
          const now = moment.utc().format('DD MM YYYY hh:mm:ss')
          return moment.utc(now,'DD MM YYYY hh:mm:ss').diff(moment.utc(this.time,'DD MM YYYY hh:mm:ss'), type)
        } else {
          return default_value
        }
      },
      reset(){
        this.time=''
      },
    }
  }
  if(count_down) {
    obj.count_down = {
      seconds: 0,
      timer: new Timer({
        tick: 1,
        ontick(){
          obj.count_down.seconds -= 1;
        },
        onend(){
          obj.count_down.seconds = 0;
        },
      }),
      start(value,unit='second'){
        const time = moment().utc().add(value,unit).format('DD MM YYYY hh:mm:ss')
        const now = moment.utc().format('DD MM YYYY hh:mm:ss')
        const dif = moment.utc(time,'DD MM YYYY hh:mm:ss').diff(moment.utc(now,'DD MM YYYY hh:mm:ss'), 'seconds')
        this.seconds=dif
        this.timer.start(dif)
      },
      reset(){
        this.timer.stop()
        this.seconds=0
      },
    }
  }
  const reset = function(message){
    const { callBack, cancel_message } = message ?? {}
    if(count) obj.count=0
    if(source) {
      obj.source?.cancel?.(cancel_message??'Cancel Request')
      obj.source=null
    }
    if(status) obj.status=0
    if(error_message) obj.error_message=''
    if(success_message) obj.success_message=''
    if(validation_errors) obj.validation_errors={}
    if(response_error) obj.error={}
    if(upload_progress) obj.upload_progress.reset()
    if(request_time) obj.request_time.reset()
    if(count_down) obj.count_down.reset()
    if(error_trigger) obj.error_trigger.reset()
    obj.send = send ?? false
    callBack?.(obj)
  }
  obj.reset = reset.bind(obj)
  obj.send = send ?? false
  return obj
}