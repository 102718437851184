import { storage } from '@/service/store.service';
import createPersistedState from 'vuex-persistedstate';


export default createPersistedState({
  key: storage.APP.key,
  paths: [
    `app.user`,
  ],
  storage: {
    getItem: (key) => key==storage.APP.key ? storage.APP.get() : '',
    setItem: (key, value) => key==storage.APP.key ? storage.APP.set(value) : '',
    removeItem: (key) => key==storage.APP.key ? storage.APP.remove() : '',
  }
});