export default {
  CANVAS: {
    BEFORE_TRANSFORM: 'before:transform',
    AFTER_RENDER: 'after:render',
    PATH_CREATED: 'path:created',
    SELECTION: {
      BEFORE_SELECTION_CLEARED: 'before:selection:cleared',
      SELECTION_CLEARED: 'selection:cleared',
      SELECTION_CREATED: 'selection:created',
      SELECTION_UPDATED: 'selection:updated',
    },
    MOUSE: {
      MOUSE_UP: 'mouse:up',
      MOUSE_DOWN: 'mouse:down',
      MOUSE_MOVE: 'mouse:move',
      MOUSE_UP_BEFORE: 'mouse:up:before',
      MOUSE_DOWN_BEFORE: 'mouse:down:before',
      MOUSE_MOVE_BEFORE: 'mouse:move:before',
      MOUSE_DBCLICK: 'mouse:dblclick',
      MOUSE_WHEEL: 'mouse:wheel',
      MOUSE_OVER: 'mouse:over',
      MOUSE_OUT: 'mouse:out',
    },
    DROP: {
      DROP_BEFORE: 'drop:before',
      DROP: 'drop',
      DROP_OVER: 'dragover',
      DROP_ENTER: 'dragenter',
      DROP_LEAVE: 'dragleave',
    },
    OBJECT: {
      OBJECT_ADDED: 'object:added',
      OBJECT_REMOVED: 'object:removed',
      OBJECT_MODIFIED: 'object:modified',
      OBJECT_MOVING: 'object:moving',
      OBJECT_SCALING: 'object:scaling',
      OBJECT_ROTATING: 'object:rotating',
      OBJECT_SKEWING: 'object:skewing',
      OBJECT_RESIZING: 'object:resizing',
    },
  },
  OBJECT: {
    MOVING: 'moving',
    SCALING: 'scaling',
    ROTATING: 'rotating',
    SKEWING: 'skewing',
    RESIZING: 'resizing',
    MOUSE: {
      MOUSE_UP: 'mouseup',
      MOUSE_DOWN: 'mousedown',
      MOUSE_MOVE: 'mousemove',
      MOUSE_UP_BEFORE: 'mouseup:before',
      MOUSE_DOWN_BEFORE: 'mousedown:before',
      MOUSE_MOVE_BEFORE: 'mousemove:before',
      MOUSE_DBCLICK: 'mousedblclick',
      MOUSE_WHEEL: 'mousewheel',
      MOUSE_OVER: 'mouseover',
      MOUSE_OUT: 'mouseout',
    },
    DROP: {
      DROP_BEFORE: 'drop:before',
      DROP: 'drop',
      DROP_OVER: 'dragover',
      DROP_ENTER: 'dragenter',
      DROP_LEAVE: 'dragleave',
    },
  },
}