<template>
  <div class="bill-over-lay maintainaceScreen-container" v-if="conditions.maintenance">
    <div class="maintainaceScreen-inner">
      <b-alert :show="!!api.check_maintenance.error_message" variant="danger">{{ api.check_maintenance.error_message }}</b-alert>
      <img class="maintainaceScreen-img" :src="require('@/assets/images/maintenanceScreen-gif.gif')" alt="maintainance " />
      <div class="maintainaceScreen-heading">Maintenance in progress</div>
      <div class="maintainaceScreen-text">
        Sorry for the inconvenience! Our system is taking a short break to come back stronger than ever. 
        <br/>
        <br/>
        Stay tuned for updates!
      </div>
      <button class="maintainaceScreen-button" :disabled="api.check_maintenance.send" @click="checkMaintenance()">
        <b-spinner v-if="api.check_maintenance.send" variant="primary" />
        <template v-else>Try again</template>
      </button>
    </div>
  </div> 
</template>

<script>
import { API } from '@/service';
import { mapActions, mapGetters } from 'vuex';
import { GET_CP_DOMAIN, GET_CP_URL } from '@/store/constants/getters';
import { CENTERAL_POINT_URL_DOMAIN } from '@/store/constants/actions';
let timeout_interval;
export default {
  name: 'AppCommon',
  data() {
    return {
      conditions: {
        maintenance: false,
      },
      api: {
        check_maintenance: this.$app.api({
          error_message: true,
        }),
      }
    }
  },
  computed: {
    ...mapGetters([
      GET_CP_DOMAIN,
      GET_CP_URL
    ]),
  },
  methods: {
    ...mapActions([
      CENTERAL_POINT_URL_DOMAIN
    ]),
    async checkMaintenance(){
      let vm = this
      if(this.api.check_maintenance.send) return;
      try {
        clearTimeout(timeout_interval)
        this.api.check_maintenance.send=true
        this.api.check_maintenance.error_message=''
        if(!this.GET_CP_URL){
          const { data } = await API.endpoints.dns.getCP({
            domain: this.GET_CP_DOMAIN
          })
          this.CENTERAL_POINT_URL_DOMAIN(data)
        }
        const { data } = await API.endpoints.dns.checkMaintenance({
          url: this.GET_CP_URL
        })
        this.conditions.maintenance=!!data?.maintenance
      } catch(ex) {
        this.api.check_maintenance.error_message=ex.own_message || ex.message
      } finally {
        this.api.check_maintenance.send=false
        timeout_interval = setTimeout(()=>{
          vm.checkMaintenance()
        },10*60*1000)
      }
    },
  },
  mounted(){
    let vm = this
    setTimeout(()=>{
      vm.checkMaintenance()
    },2*1000)
  },
  beforeDestroy(){
    if(timeout_interval) clearTimeout(timeout_interval)
  }
}
</script>

<style>

</style>