<template>
  <div class="dialer-loader">
    <div class="d-flex justify-content-center align-items-center h-80">
      <div class="dialer-loader align-items-center">
        <div class="dot" :style="`background-color:${localVariant || color || ''};`"></div>
        <div class="dot" :style="`background-color:${localVariant || color || ''};`"></div>
        <div class="dot" :style="`background-color:${localVariant || color || ''};`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SCSSVariables from "@/assets/scss/_export.scss";
export default {
  name: "AppSpinner",
  props: ["color", "variant"],
  data() {
    return {
      localVariant: "",
    };
  },
  watch: {
    variant: {
      immediate: true,
      handler(variant) {
        if (!variant) return;
        this.localVariant = SCSSVariables[variant] || '';
      },
    },
  },
};
</script>

<style lang="scss" >
// .dialer-loader {
//   svg {
//     animation: spin 1s linear infinite;
//   }
// }
.loader-container{
  // left:-71px;
  // top:-23px;
  width:34px;
  // margin-right: -34px;
  margin-right: 8px;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 34px;
  height: 10px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  top:0px;
  width: 8px;
  background: #fff;
  animation: lds-facebook 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 0px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 12px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 24px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 35px;
  }
  50%, 100% {
    top: 24px;
    height: 10px;
  }
}


.dialer-loader {
  display: flex;
  height: 12px;
  width: 42px;
  position: absolute;
  left:5px;
  // margin-left: -20px;
  .dot {
    // width: 16px;
    // height: 16px;
    width: 10px;
    height: 10px;
    background: white;
    // @include border-radius(50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    animation: 1.3s slide infinite ease-in-out both;
    margin: 0px 2px;
    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
  @keyframes slide {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  &.big{
    // margin:auto;
    margin-top:0px;
    height: 250px;
    width: 300px;
    position: relative;
    top:0px;
    left:50%;
    transform: translateX(-50%);
    .dialer-loader {
      height: 250px;
      width: 300px;
      position: relative;
      display: flex;
      justify-content: center;
    }
    &.vertically-centered{
      align-items: center;
      height: 100vh;
    }
    .dot{
      background-color: #5576d1;
      width: 75px;
      height: 75px;
    }
  }
}
</style>