/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { SERVICE_WORKER_LOGGER } from './service/index'
import store from './store'

register(`${process.env.BASE_URL}service-worker.js`, {
  ready (...args) {
    SERVICE_WORKER_LOGGER.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    ,...args)
  },
  registered (registration) {
    SERVICE_WORKER_LOGGER.log('Service worker has been registered.',registration)
    store.commit('setState',{ service_registration: registration })
    registration.addEventListener('fetch',function(...args){
      SERVICE_WORKER_LOGGER.log('fetch registration',...args)
    })
  },
  cached (...args) {
    SERVICE_WORKER_LOGGER.log('Content has been cached for offline use.',...args)
  },
  updatefound (...args) {
    SERVICE_WORKER_LOGGER.log('New content is downloading.',...args)
  },
  updated (...args) {
    SERVICE_WORKER_LOGGER.log('New content is available; please refresh.',...args)
  },
  offline (...args) {
    SERVICE_WORKER_LOGGER.log('No internet connection found. App is running in offline mode.',...args)
  },
  error (error) {
    SERVICE_WORKER_LOGGER.danger('Error during service worker registration:', error)
  },
})
