import moment from 'moment';
export function filter_date_current(value,options={}){
  let moment_instance = null
  if(options?.input?.unix){
    moment_instance = moment.unix(value)
  } else {
    moment_instance = moment.utc(value)
  }
  if(options?.output?.formate) {
    return moment_instance.format(options.formate)
  } else {
    return moment_instance.calendar(null, {
      sameDay: options?.output?.calender?.sameDay || '[Today]',
      nextDay: options?.output?.calender?.nextDay || '[Tomorrow]',
      nextWeek: options?.output?.calender?.nextWeek || 'dddd',
      lastDay: options?.output?.calender?.lastDay || '[Yesterday]',
      lastWeek: options?.output?.calender?.lastWeek || '[Last] dddd',
      sameElse: options?.output?.calender?.sameElse || 'DD MMM YYYY'
    })
  }
}