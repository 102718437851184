<template>
  <modal 
    height="auto" 
    width="100%" 
    class="DeveloperSettingsModal" 
    :name="modalName" 
    :scrollable="true" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between">
      <h2 class="dialer-edit-title newer mb-0">
        <template v-if="api.verify_pincode.status!=1">Verify Pincode</template>
        <template v-else>Select Setup</template>
      </h2>
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="dialer-button dialer-button-delete" @click="$modal.hide(modalName)">Close</button>
      </div>
    </div>
    <div>
      <div class="dialer-login-form">
        <b-form v-if="api.verify_pincode.status!=1" @submit.prevent="verifyPincode()">
          <div>
            <div>
              <div class="signupLoginV2-section-2-right">
                <b-alert variant="danger" :show="!!api.verify_pincode.error_message">{{ api.verify_pincode.error_message }}</b-alert>
                <div>
                  <div class="mb-32px">
                    <div class="latestPin-container bigger d-flex flex-column align-items-center">
                      <label class="mb-16px">Enter PIN</label>
                      <div class="latestPin-inner">
                        <app-pin-code
                          :disabled="api.verify_pincode.send"
                          class="input"
                          :numberslength="$v.forms.verify_pincode.pincode.$params.maxLength.max"
                          @change="forms.verify_pincode.pincode=$event"
                        />
                      </div>
                    </div>
                    <template v-if="forms.verify_pincode.submitted && $v.forms.verify_pincode.pincode.$invalid">
                      <b-icon id="su-email" icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                      <div class="errorTemplate-v2">
                        <p class=" animated bounceIntop">
                          <span v-if="!$v.forms.verify_pincode.pincode.required">* pincode is required</span>
                          <span v-else-if="!$v.forms.verify_pincode.pincode.maxLength">* pincode can be maximum {{$v.forms.verify_pincode.pincode.$params.maxLength.max}} character</span>
                          <span v-else-if="!$v.forms.verify_pincode.pincode.minLength">* pincode should be minimum {{$v.forms.verify_pincode.pincode.$params.minLength.min}} character</span>
                        </p>
                      </div>
                    </template>
                  </div>
                  <div class="formSubmit w-100 d-flex justify-content-end">
                    <div class="button-container-v2">
                      <div class="button-container-inner">
                        <b-button :disabled="api.verify_pincode.send"  class="login-button-v2" type="submit" variant="primary">
                          <app-spinner v-if="api.verify_pincode.send" />
                          <template v-else>
                            Check
                            <b-icon icon="arrow-right-short" class="ml-24px iconi" scale="1.5"/>
                          </template>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
        <b-form v-else @submit.prevent="check()">
          <div>
            <div>
              <div class="signupLoginV2-section-2-right">
                <div>
                  <div :class="`mainInput-container mb-32px`">
                    <label>Select Centeral Setup</label>
                    <b-select 
                      class="bill-select-input" 
                      :disabled="api.fetch_centeral_points.send || api.set_centeral_point.send" 
                      :value="GET_CP_DOMAIN" 
                      @change="updateCenteralPoint($event);"
                    >
                      <b-select-option v-for="option in centeralPointOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                    </b-select>
                  </div>
                  <div :class="`mainInput-container mb-32px`">
                    <label>Select Mothership Setup</label>
                    <b-select 
                      class="bill-select-input" 
                      :disabled="api.fetch_centeral_points.send || api.fetch_motherships.send || api.set_centeral_point.send" 
                      :value="GET_MS_ID" 
                      @change="SET_MS_ID($event);SET_HARMONY_ID();fetchHarmonies();"
                    >
                      <b-select-option v-for="option in mothershipOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                    </b-select>
                  </div>
                  <div :class="`mainInput-container mb-32px`">
                    <label>Select Harmony Setup</label>
                    <b-select 
                      class="bill-select-input" 
                      :disabled="api.fetch_centeral_points.send || api.fetch_motherships.send || api.fetch_harmonies.send || api.set_centeral_point.send" 
                      :value="GET_HARMONY_ID" 
                      @change="SET_HARMONY_ID($event);"
                    >
                      <b-select-option v-for="option in harmonyOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                    </b-select>
                  </div>
                  <div class="dialer-box alt">
                    <div class="dialer-flex">
                      <div class="dialer-box-header">Signup test Mode</div>
                      <div>
                        <b-form-checkbox  
                          class="dialer-switch IosSwitch" 
                          switch 
                          ref="signup_test_mode" 
                          :disabled="api.fetch_signup_test_mode.send || api.toggle_signup_test_mode.send"
                          :checked="forms.signup_test_mode.test_mode"
                          @change="toggleSignupTestMode()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </modal>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { API } from '@/service';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GET_CP_DOMAIN, GET_MS_ID, GET_HARMONY_ID } from '@/store/constants/getters';
import { SET_MS_ID, SET_HARMONY_ID } from '@/store/constants/mutations';
import { CENTERAL_POINT_URL_DOMAIN } from '@/store/constants/actions';

export default {
  name: 'DeveloperSetting',
  props: {
    modalName: {
      type: String,
      default: 'DeveloperSetting',
    },
  },
  data(){
    return {
      forms: {
        verify_pincode: this.$app.form({
          data: {
            pincode: '',
          }
        }),
        signup_test_mode: this.$app.form({
          data: {
            test_mode: false,
          }
        }),
      },
      response: {
        centeral_points: [],
        motherships: [],
        harmonies: [],
      },
      api: {
        verify_pincode: this.$app.api({
          status: true, 
          error_message: true, 
        }),
        fetch_centeral_points: this.$app.api(),
        fetch_motherships: this.$app.api(),
        fetch_harmonies: this.$app.api(),
        fetch_signup_test_mode: this.$app.api(),
        toggle_signup_test_mode: this.$app.api(),
        set_centeral_point: this.$app.api(),
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_CP_DOMAIN,
      GET_MS_ID, 
      GET_HARMONY_ID,
    ]),
    centeralPointOptions(){ return this.response.centeral_points?.map(i=>({text:i.name,value:i.domain})) ?? [] },
    mothershipOptions(){ return this.response.motherships?.map?.(i=>({text:`${i.prefix} (${i.server}) ${i.signup_server==1?'Default':''}`,value:i.id})) ?? [] },
    harmonyOptions(){ return this.response.harmonies?.map?.(i=>({text:`${i.name} (${i?.portal_servers?.server ?? ''}) ${i.is_default==1?'Default':''}`,value:i.id})) ?? [] },
  },
  validations: {
    forms: {
      verify_pincode: {
        pincode: {
          required,
          maxLength: maxLength(6),
          minLength: minLength(6),
        },
      },
    },
  },
  methods: {
    ...mapActions([
      CENTERAL_POINT_URL_DOMAIN
    ]),
    ...mapMutations([
      SET_MS_ID,
      SET_HARMONY_ID,
    ]),
    onBeforeOpen(){
      
    },
    onBeforeClose(){
      this.response.centeral_points = []
      this.response.motherships = []
      this.response.harmonies = []
      this.forms.verify_pincode.reset()
      this.forms.signup_test_mode.reset()
      this.api.verify_pincode.reset()
      this.api.fetch_centeral_points.reset()
      this.api.fetch_motherships.reset()
      this.api.fetch_harmonies.reset()
      this.api.set_centeral_point.reset()
      this.api.fetch_signup_test_mode.reset()
      this.api.toggle_signup_test_mode.reset()
    },
    async verifyPincode(){
      this.forms.verify_pincode.submitted=true
      this.$v.forms.verify_pincode.$touch()
      if(this.$v.forms.verify_pincode.$invalid || this.api.verify_pincode.send) return;
      try {
        this.api.verify_pincode.send=true
        this.api.verify_pincode.status=0
        this.api.verify_pincode.error_message=''
        await API.endpoints.dns.verifyPincode({
          pincode: this.forms.verify_pincode.pincode,
        })
        this.api.verify_pincode.status=1
        this.fetchCenteralPoints()
        this.fetchSignupTestMode()
      } catch (ex) {
        this.api.verify_pincode.error_message=ex.own_message || ex.message
        this.api.verify_pincode.status=2
      } finally {
        this.api.verify_pincode.send=false
        this.forms.verify_pincode.submitted=false
      }
    },
    // centeral points
    async fetchCenteralPoints(){
      if(this.api.fetch_centeral_points.send) return;
      try {
        this.api.fetch_centeral_points.send=true
        const { data } = await API.endpoints.dns.getCPList()
        this.response.centeral_points = data || [];
        this.response.motherships = [];
        this.response.harmonies = [];
        this.fetchMotherships()
      } catch(ex) {
        this.$toast.open({
          message: ex.own_message,
          type: 'error',
        })
      } finally {
        this.api.fetch_centeral_points.send=false
      }
    },
    async updateCenteralPoint(domain){
      if(this.api.set_centeral_point.send) return;
      try {
        this.api.set_centeral_point.send=true
        const { data } = await API.endpoints.dns.getCP({
          domain: domain,
        })
        this.CENTERAL_POINT_URL_DOMAIN(data)
        this.fetchMotherships()
        this.fetchSignupTestMode()
        this.$toast.open({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.$toast.open({
          message: ex.own_message,
          type: 'error',
        })
      } finally {
        this.api.set_centeral_point.send=false
      }
    },
    // mothership
    async fetchMotherships(){
      if(this.api.fetch_motherships.send || !this.GET_CP_DOMAIN) return;
      try {
        this.api.fetch_motherships.send=true
        const { data } = await API.endpoints.auth.fetchMotherships()
        this.response.motherships = data?.data || [];
        this.response.harmonies = [];
        this.fetchHarmonies()
      } catch (ex) {
        this.response.motherships = [];
        this.response.harmonies = [];
        this.$toast.open({
          message: ex.own_message,
          type: 'error',
        })
      } finally {
        this.api.fetch_motherships.send=false
      }
    },
    // harmonies
    async fetchHarmonies(){
      if(this.api.fetch_harmonies.send || !this.GET_MS_ID) return;
      try {
        this.api.fetch_harmonies.send=true
        const { data } = await API.endpoints.auth.fetchHarmonies({
          ms_server: this.GET_MS_ID,
        })
        this.response.harmonies = data?.data || [];
      } catch (ex) {
        this.response.harmonies = [];
        this.$toast.open({
          message: ex.own_message,
          type: 'error',
        })
      } finally {
        this.api.fetch_harmonies.send=false
      }
    },
    // test mode
    async fetchSignupTestMode(){
      if(this.api.fetch_signup_test_mode.send) return;
      try {
        this.api.fetch_signup_test_mode.send=true
        const { data } = await API.endpoints.auth.fetchSignupTestMode()
        this.forms.signup_test_mode.test_mode=!!data
      } catch (ex) {
        this.$toast.open({
          message: ex.own_message,
          type: 'error'
        })
      } finally {
        this.api.fetch_signup_test_mode.send=false
      }
    },
    async toggleSignupTestMode(){
      if(this.api.toggle_signup_test_mode.send) return;
      try {
        this.api.toggle_signup_test_mode.send=true
        await API.endpoints.auth.toggleSignupTestMode({
          test_mode: this.forms.signup_test_mode.test_mode ? '0' : '1'
        })
        this.forms.signup_test_mode.test_mode=!this.forms.signup_test_mode.test_mode
        this.$toast.open({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.$toast.open({
          message: ex.own_message,
          type: 'error'
        })
      } finally {
        this.$refs['signup_test_mode'].localChecked = this.forms.signup_test_mode.test_mode
        this.api.toggle_signup_test_mode.send=false
      }
    },
  },
}
</script>

<style>

</style>



<style lang="scss">
.DeveloperSettingsModal.vm--container{
  & > .vm--modal{
    right: 0;
    left: auto !important;
    top: 0 !important;
    border-radius: 0;
    min-height: 100vh;
    min-width: 50%;
    width: 600px !important;
    overflow-y: auto;
    position: absolute;
    display: inline-block;
    transition: right 0.5s, left 0.5s;
    padding: 0 40px;
    .dialer-edit-title.newer{
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 0px;
      font-size: 22px;
      color: #121212;
      display: inline-block;
    }
    .dialer-button.dialer-button-delete{
      margin-left: 20px;
      width: 100px;
      color: #575757;
      border: none !important;
      background-color: #dedede;
      border-radius: 5px !important;
      height: 50px;
      font-size: 16px;
      padding: 12px 24px;
      outline: none;
      cursor: pointer;
      font-weight: 500;
    }
    .latestGreyBox-9-9-2023 {
      border-radius: 13px;
      background-color: #F9F9F9;
      padding: 30px;
      .latestPin-container .latestPin-inner .react-code-input.input > input{
        border-width: 2px;
        &:first-child{
          margin-left: 0px;
        }
      }
    }
  }
}
</style>