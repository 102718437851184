import { 
  GET_IS_LOGGED_IN,
  GET_TOKEN,
} from "../constants/getters";
import { 
  SET_TOKEN,
} from "../constants/mutations";
export default {
  state: {
    token: '',
  },
  getters: {
    [GET_IS_LOGGED_IN](state,getters){ return !!getters[GET_TOKEN] },
    [GET_TOKEN](state){ return state.token },
  },
  actions: {
    
  },
  mutations: {
    [SET_TOKEN](state,value){
      state.token=value ?? ''
    },
  },
};