/* eslint-disable no-dupe-class-members */
import Emitter from "component-emitter";
import JwtDecode from "jwt-decode";
import _ from 'lodash';
import { MEDIA_DEVICES, PERMISSIONS, HELPER_FUNCTIONS } from "../../utils/index";
import { JITSI_LOGGER } from "../../service/index";
import Record from "./record";
import Whiteboard from "./whiteboard/index";

const commands = {
  CHANNEL_ID_CHANGED: "CHANNEL_ID_CHANGED",
  CHANNEL_DATA: "CHANNEL_DATA",
  EMOJI: "EMOJI",
  WHITE_BOARD_DATA: "WHITE_BOARD_DATA",
}
const events = {
  distroy: {
    value: 'distroy',
    callBack: 'onDistroy',
    callBackFunction: 'ondistroy',
  },
  unhold: {
    value: 'unhold',
    callBack: 'onUnHold',
    callBackFunction: 'onunhold',
  },
  userjoined: {
    value: 'userjoined',
    callBack: 'onUserJoined',
    callBackFunction: 'onuserjoined',
  },
  userleft: {
    value: 'userleft',
    callBack: 'onUserLeft',
    callBackFunction: 'onuserleft',
  },
  reaction: {
    value: 'reaction',
    callBack: 'onReaction',
    callBackFunction: 'onreaction',
  },
  message: {
    value: 'message',
    callBack: 'onMessage',
    callBackFunction: 'onmessage',
  },
  incomingmessage: {
    value: 'incomingmessage',
    callBack: 'onIncomingMessage',
    callBackFunction: 'onincomingmessage',
  },
}
const emojis = {
  hand: {
    value: 'hand',
    icon: '🖐',
    default: true,
    classC : 'hand',
  },
  clapping: {
    value: 'clapping',
    icon: '👏',
    default: false,
    classC : 'clapping',
  },
  ok: {
    value: 'ok',
    icon: '👍',
    default: false,
    classC : 'ok',
  },
  amazed: {
    value: 'amazed',
    icon: '😧',
    default: false,
    classC : 'amazed',
  },
  smile: {
    value: 'smile',
    icon: '😀',
    default: false,
    classC : 'smile',
  },
  angry: {
    value: 'angry',
    icon: '😡',
    default: false,
    classC : 'angry',
  },
  stunned: {
    value: 'stunned',
    icon: '😶',
    default: false,
    classC : 'stunned',
  },
  middleFinger: {
    value: 'middleFinger',
    icon: '🖕',
    default: false,
    development: true,
    classC : 'middleFinger',
  },
}
const performance_settings = {
  high: {
    value: 'high',
    video_resolution: 720,
    show_video: true,
  },
  standard: {
    value: 'standard',
    video_resolution: 360,
    show_video: true,
  },
  low: {
    value: 'low',
    video_resolution: 180,
    show_video: true,
  },
  none: {
    value: 'none',
    video_resolution: 0,
    show_video: false,
  },
}
const views = {
  grid: {
    value: 'grid',
    text: 'Grid View',
  },
  contain: {
    value: 'contain',
    text: 'Contain View',
  },
  speaker: {
    value: 'speaker',
    text: 'Speaker View',
  },
}
const sounds_type = {
  reaction: 'reaction',
  incoming_message: 'incoming_message',
  user_joined: 'user_joined',
  user_left: 'user_left',
}
let reaction_interval;
let time_interval;
let speaker_interval;
class RunningCall {
  id = ''
  #el_ids = {
    white_board: '',
    white_board_canvas: '',
    main: ''
  }
  jitsi = {
    url: '',
    connection: {},
    conference: {},
    token: '',
    payload: {},
    timestamp: 0,
    user_id: '',
  }
  conference = {
    name: '',
    phone_number: '',
  }
  before_hold = {
    mute: {
      video: false,
      audio: false
    },
  }
  time = 0
  video = {
    type: 'video',
    camera_id: '',
    mute: false,
    resolution: performance_settings.standard.video_resolution,
    camera_permission: '',
    mirror: false,
    desktop: false,
    error_message: '',
  }
  audio = {
    type: 'audio',
    mic_id: '',
    mute: false,
    volume: 100,
    speaker_id: '',
    mic_permission: '',
    desktop: false,
    speaker_stats: 0,
  }
  tracks = {
    local: [],
    remote: [],
  }
  recorder = null
  is_conference = false
  is_group = false
  is_connected = false
  is_join = false
  is_leave = false
  is_hold = false
  is_full_screen = false
  is_hand_rise = false
  speaker_id = ''
  view = views.grid.value
  reaction = {
    id: '',
    emoji: '',
  }
  feed_back = {
    rate: null,
    detail: '',
  }
  meeting_id = ''
  messages = []
  performance_setting = performance_settings.standard.value
  call_startup = {
    video: true,
    audio: true,
    desktop: false,
  }
  participants = []
  mattermost = {
    channel_id: '',
    type: ''
  }
  sounds = {
    reaction: true,
    incoming_message: true,
    user_joined: true,
    user_left: true,
  }
  white_board = null
  call_backs = null
  onshortcutkeypress = null
  ondistroy = null
  onunhold = null
  onuserjoined = null
  onuserleft = null
  onreaction = null
  onmessage = null
  onincomingmessage = null
  get elIds(){ return this.#el_ids }
  get speaker(){ return this.participants.find(participant=>participant._id==this.speaker_id) }
  get views(){ return views }
  get performanceSettings(){ return performance_settings }
  get performanceSetting(){ return performance_settings[this.performance_setting] }
  get isDektopSharing(){ return this.tracks.local.find(track=>track.type=='video' && track.videoType=='desktop') }
  get inviteLink(){ return `${window.location.origin}/meet?meeting_id=${this.meeting_id}` }
  get emojis(){ return Object.values(emojis).filter(i=>!i.default) }
  get defaultEmoji(){ return Object.values(emojis).find(i=>i.default) }
  get reactEmoji(){ return emojis[this.reaction.emoji] }
  get reactParticipant(){ return this.participants.find(participant=>participant._id==this.reaction.id) }
  get isReaction(){ return this.reaction.emoji }
  get commands() { return commands }
  get events(){ 
    let obj = {}
    for (const [key, value] of Object.entries(events)) {
      obj[key]=value.value
    }
    return obj 
  }
  get selectedMic(){ return MEDIA_DEVICES.audioinputs.find(device=>device.deviceId == this.audio.mic_id) }
  get selectedSpeaker(){ return MEDIA_DEVICES.audiooutputs.find(device=>device.deviceId == this.audio.speaker_id) }
  get selectedCamera(){ return MEDIA_DEVICES.videoinputs.find(device=>device.deviceId == this.video.camera_id) }
  get totalHandRise(){ return this.participants.reduce((count, value)=>value?._properties?.emoji == this.defaultEmoji.value ? count + 1 : count) }

  set callBacks(value) {
    if(value==null){
      this.call_backs=null
    } else {
      this.call_backs = {
        ...this.call_backs ? this.call_backs : {},
        ...value
      }
    }
  }
  set performanceSetting(value){
    const performance_setting = performance_settings[value]
    if(!performance_setting) return;
    this.performance_setting=performance_setting.value
    this.video.resolution=performance_setting.video_resolution
    if(performance_setting.video_resolution==0){
      this.videoMute()
    } else {
      this.videoUnMute()
    }
  }
  constructor(props){
    Emitter(this)
    const random_id = HELPER_FUNCTIONS.generateID()
    this.#el_ids.white_board=`white-board-${random_id}`
    this.#el_ids.white_board_canvas=`white-board-canvs-${random_id}`
    this.#el_ids.main=`main-${random_id}`
    this.id=props?.id
    this.conference.name=props?.conference?.name
    this.conference.phone_number=props?.conference?.phone_number
    this.meeting_id=props?.meeting_id || ''
    this.is_conference=!!props?.callType?.includes?.('conference')
    this.is_group=!!props?.callType?.includes?.('group')
    this.call_startup.video=!!props?.callType?.includes?.('video')
    this.call_startup.audio=!!props?.callType?.includes?.('audio')
    this.video.mute=!props?.callType?.includes?.('video')
    this.audio.mute=!props?.callType?.includes?.('audio')
    this.jitsi.token=props?.token
    this.jitsi.url=props?.url || ''
    this.jitsi.payload=JwtDecode(this.jitsi.token)
    this.video.camera_id=MEDIA_DEVICES.camera_id
    this.audio.mic_id=MEDIA_DEVICES.mic_id
    this.audio.speaker_id=MEDIA_DEVICES.speaker_id
    this.video.camera_permission=PERMISSIONS.camera
    this.audio.mic_permission=PERMISSIONS.mic
    MEDIA_DEVICES.on(MEDIA_DEVICES.events.videoinput_change,this.#VIDEOINPUT_DEVICES_CHANGE.bind(this))
    MEDIA_DEVICES.on(MEDIA_DEVICES.events.audioinput_change,this.#AUDIOINPUT_DEVICES_CHANGE.bind(this))
    MEDIA_DEVICES.on(MEDIA_DEVICES.events.audiooutput_change,this.#AUDIOOUTPUT_DEVICES_CHANGE.bind(this))
    PERMISSIONS.on(PERMISSIONS.events.change_mic_permission,this.#MIC_PERMISSION_CHANGE.bind(this))
    PERMISSIONS.on(PERMISSIONS.events.change_camera_permission,this.#CAMERA_PERMISSION_CHANGE.bind(this))
    document.addEventListener('fullscreenchange',this.#FULLSCREEN_CHANGE.bind(this))
  }
  changeCameraId(device_id=''){
    try {
      const index = MEDIA_DEVICES.videoinputs.findIndex(item=>item.deviceId==device_id)
      if(index==-1) throw 'Device not exits'
      if(this.video.camera_id==device_id) throw 'This device is already use'
      this.video.camera_id=device_id
      this.cameraTrack()
    } catch(ex) {
      JITSI_LOGGER.danger({error:ex})
    }
  }
  changeMicId(device_id=''){
    try {
      const index = MEDIA_DEVICES.audioinputs.findIndex(item=>item.deviceId==device_id)
      if(index==-1) throw 'Device not exits'
      if(this.audio.mic_id==device_id) throw 'This device is already use'
      this.audio.mic_id=device_id
      this.audioTrack()
    } catch(ex) {
      JITSI_LOGGER.danger({error:ex})
    }
  }
  changeSpeakerId(device_id=''){
    try {
      JITSI_LOGGER.log(this,MEDIA_DEVICES.audiooutputs,device_id)
      const index = MEDIA_DEVICES.audiooutputs.findIndex(item=>item.deviceId==device_id)
      if(index==-1) throw 'Device not exits'
      if(this.audio.speaker_id==device_id) throw 'This device is already use'
      this.audio.speaker_id=device_id
    } catch(ex) {
      JITSI_LOGGER.danger({error:ex})
    }
  }
  async cameraTrack(){
    try {
      let existing_video = this.tracks.local.find((track) => track.type === "video");
      const [video] = await window.JitsiMeetJS.createLocalTracks({
        devices: ["video"],
        resolution: this.video.resolution,
        cameraDeviceId: this.video.camera_id,
        facingMode: "user",
        minFps: 30,
        maxFps: 60,
      });
      this.video.error_message=''
      if (existing_video) {
        // await this.jitsi.conference.removeTrack(existing_video)
        // await this.jitsi.conference.addTrack(video);
        await this.jitsi.conference.replaceTrack(existing_video, video);
      } else {
        await this.jitsi.conference.addTrack(video)
      }
      this.video.mute=false
      this.video.desktop=false
    } catch (ex) {
      this.video.mute=true
      console.log(':::ex.message',{ex})
      if(PERMISSIONS.camera=='denied'){
        this.video.error_message='Your camera Permissions is Blocked'
      } else if(!this.video.camera_id){
        this.video.error_message='You dont have camera device'
      } else if(ex.name=='gum.general'){
        this.video.error_message='Your camera device is in use by another application'
      } else if(ex?.message){
        this.video.error_message=ex?.message
      } else {
        this.video.error_message='There is something went wrong in starting the video camera'
      }
      throw new Error(ex.message)
    }
  }
  async audioTrack() {
    try {
      const audio = this.tracks.local.find((track) => track.type === "audio");
      const [new_audio] = await window.JitsiMeetJS.createLocalTracks({
        devices: ["audio"],
        micDeviceId: this.audio.mic_id,
      });
      if (audio) {
        await this.jitsi.conference.replaceTrack(audio, new_audio);
      } else {
        await this.jitsi.conference.addTrack(new_audio)
      }
      this.audio.mute=false
      this.audio.desktop=false
    } catch (ex) {
      this.audio.mute=true
      throw new Error(ex.message)
    }
  }
  async shareScreen(){
    try {
      if (!window.JitsiMeetJS.isDesktopSharingEnabled()) throw new Error('desktop sharing are not available')
      let existing_video = this.tracks.local.find((track) => track.type === "video");
      const desktop = await window.JitsiMeetJS.createLocalTracks({
        devices: ["desktop"],
        resolution: this.video.resolution,
        desktopSharingFrameRate: {
          min: 30,
          max: 60,
        },
      });
      const desktopVideo = desktop.find((track)=>track.type=='video');
      const desktopaudio = desktop.find((track)=>track.type=='audio');
      if(desktopaudio) desktopaudio.dispose()
      if(!desktopVideo) throw new Error('Please Select a video screen')
      if (existing_video) {
        // await this.jitsi.conference.removeTrack(existing_video)
        // await HELPER_FUNCTIONS.sleep(3 * 1000)
        // await this.jitsi.conference.addTrack(desktopVideo);
        await this.jitsi.conference.replaceTrack(existing_video, desktopVideo);
      } else {
        await this.jitsi.conference.addTrack(desktopVideo)
      } 
      this.video.mute=false
      this.video.desktop=true
    } catch (ex) {
      JITSI_LOGGER.danger({ex})
      throw new Error(ex.message)
    }
  }
  async stopShareScreen(){
    try {
      if (!window.JitsiMeetJS.isDesktopSharingEnabled()) throw new Error('desktop sharing are not available')
      let existing_video = this.tracks.local.find((track) => track.type === "video" && track.videoType=='desktop');
      if(!existing_video) throw new Error('There is no desktop video track')
      await this.jitsi.conference.removeTrack(existing_video)
      this.cameraTrack() 
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async toggleScreenSharing(){
    if(this.video.desktop) this.stopShareScreen()
    else this.shareScreen()
  }
  async shareAudio(){
    try {
      if (!window.JitsiMeetJS.isDesktopSharingEnabled()) throw new Error('desktop sharing are not available')
      let existing_audio = this.tracks.local.find((track) => track.type === "audio");
      const desktop = await window.JitsiMeetJS.createLocalTracks({
        devices: ["desktop"],
      });
      const desktopVideo = desktop.find((track)=>track.type=='video');
      const desktopaudio = desktop.find((track)=>track.type=='audio');
      if(desktopVideo) desktopVideo.dispose()
      if(!desktopaudio) throw new Error('Please Select a audio screen')
      if (existing_audio) {
        await this.jitsi.conference.replaceTrack(existing_audio, desktopaudio);
      } else {
        await this.jitsi.conference.addTrack(desktopaudio)
      }
      this.audio.mute=false
      this.audio.desktop=true
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async stopShareAudio(){
    try {
      if (!window.JitsiMeetJS.isDesktopSharingEnabled()) throw new Error('desktop sharing are not available')
      let existing_audio = this.tracks.local.find((track) => track.type === "audio");
      if(!existing_audio) throw new Error('There is no desktop audio track')
      await this.jitsi.conference.removeTrack(existing_audio)
      this.audioTrack() 
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async toggleAudioSharing(){
    if(this.audio.desktop) this.stopShareAudio()
    else this.shareAudio()
  }
  async videoMute(){
    try {
      if(this.video.mute) throw 'Already muted'
      const index = this.tracks.local.findIndex(track=>track.type=='video')
      if(index>-1){
        this.jitsi.conference.removeTrack(this.tracks.local[index])
        this.jitsi.conference.setLocalParticipantProperty('isVideoMuted','true')
        this.video.mute=true
      }
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async videoUnMute(){
    try {
      if(!this.video.mute) throw 'Already unmuted'
      await this.cameraTrack()
      this.jitsi.conference.setLocalParticipantProperty('isVideoMuted','false')
      this.video.mute=false
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async toggleVideoMute(){
    if(this.video.mute) this.videoUnMute()
    else this.videoMute()
  }
  async audioMute(){
    try {
      JITSI_LOGGER.log(this)
      if(this.audio.mute) throw 'Already muted'
      const audio = this.tracks.local.find((track) => track.type === "audio");
      if(audio){
        if(audio && audio.isMuted()){
          this.audio.mute=true
          throw 'Already muted'
        }
        audio.mute();
        this.audio.mute=true
      } else {
        throw 'Audio track are not created'
      }
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async audioUnMute(){
    try {
      if(!this.audio.mute) throw 'Already unmuted'
      const audio = this.tracks.local.find((track) => track.type === "audio");
      if(audio){
        if(!audio.isMuted()){
          this.audio.mute=false
          throw 'Already unmuted'
        }
        audio.unmute();
        this.audio.mute=false
      } else {
        this.audioTrack()
      }
    } catch (ex) {
      throw new Error(ex.message)
    }
  }
  async toggleAudioMute(){
    if(this.audio.mute) this.audioUnMute()
    else this.audioMute()
  }
  async toggleRecord(){
    if(this.recorder.is_started){
      this.recorder.stop()
    } else {
      this.recorder.start()
    }
  }
  async saveRecording(){
    if(this.isRecorded){
      this.recorder.save()
    }
  }
  async hold(){
    this.is_hold=true
    this.before_hold.mute.audio = this.audio.mute;
    this.before_hold.mute.video = this.video.mute;
    if(!this.video.mute) this.videoMute()
    if(!this.audio.mute) this.audioMute()
    this.jitsi.conference.setLocalParticipantProperty('isHold','true')
  }
  async unhold(){
    this.is_hold=false
    if(!this.before_hold.mute.audio){
      this.audioUnMute()
    }
    if(!this.before_hold.mute.video){
      this.videoUnMute()
    }
    this.jitsi.conference.setLocalParticipantProperty('isHold','false')
    this.#emmiting(events.unhold,{
      id: this.id
    })
  }
  async toogleHold(){
    if(this.is_hold) this.unhold()
    else this.hold()
  }
  mirrorvideo(el){
    try {
      if(!el && !el.style) throw 'argument is invalid'
      if (el.style.transform && el.style.webkitTransform) {
        el.style.transform = "";
        el.style.webkitTransform = "";
        this.jitsi.conference.setLocalParticipantProperty("isMirrorVideo",'false');
      } else {
        el.style.transform = "rotateY(180deg)";
        el.style.webkitTransform = "rotateY(180deg)";
        this.jitsi.conference.setLocalParticipantProperty("isMirrorVideo", 'true');
      }
    } catch(ex) {
      throw new Error(ex.message)
    }
  }
  createlocaltracks(){
    if(this.call_startup.video){
      this.cameraTrack()
    }
    if(this.call_startup.audio){
      this.audioTrack()
    }
  }
  async leave(){
    try {
      if(this.is_leave) throw 'already leaved'
      await this.jitsi.conference.leave();
      this.is_leave=true
      this.#distroy()
    } catch(ex) {
      throw new Error(ex.message)
    }
  }
  toggleFullScreen(){
    if(this.is_full_screen){
      HELPER_FUNCTIONS.closeFullscreen()
    } else {
      const el = document.getElementById(this.#el_ids.main)
      HELPER_FUNCTIONS.openFullscreen(el)
    }
  }
  handRise(){
    this.jitsi.conference.setLocalParticipantProperty("emoji",this.is_hand_rise ? '' : this.defaultEmoji.value);
    this.is_hand_rise=!this.is_hand_rise
  }
  react(emoji){
    if(!emoji?.value) return;
    this.jitsi.conference.sendCommandOnce(this.commands.EMOJI, {
      attributes: {
        emoji: emoji?.value,
        participant_id: this.jitsi.user_id,
      },
    });
  }
  sendFeedBack(rate, detail){
    this.jitsi.conference.sendFeedback(rate, detail)
    this.feed_back.rate=rate
    this.feed_back.detail=detail
  }
  copyLink(){
    return new Promise((resolve,reject)=>{
      window?.navigator?.clipboard?.writeText?.(this.inviteLink)
      .then(() => {
        resolve()
      })
      .catch((ex) => {
        reject(ex)
      }) ?? reject();
    })
  }
  makeconnection(){
    let that = this
    try {
      const token = this.jitsi.token
      const sub = this.jitsi.payload.sub
      const iss = this.jitsi.payload.iss
      const url = this.jitsi.url
      const domain = url.replace('https://','').replace('/http-bind','')
      const socket_url = `wss://${domain}/xmpp-websocket`
      JITSI_LOGGER.log('makeconnection',domain,url,iss,token,sub)
      this.jitsi.connection = new window.JitsiMeetJS.JitsiConnection(iss,token,{
        hosts: {
          domain: `${sub}`,
          muc: `muc.${sub}`,
        },
        serviceUrl: socket_url,
        // bosh: url,
        clientNode: domain,
        // consider_bosh_secure: true,
        enableEffect: true,
      });
      const { events: { connection: events }, } = window.JitsiMeetJS;
      this.jitsi.connection.addEventListener(events.CONNECTION_ESTABLISHED,this.#CONNECTION_ESTABLISHED.bind(this));
      this.jitsi.connection.addEventListener(events.CONNECTION_FAILED,this.#CONNECTION_FAILED.bind(this));
      this.jitsi.connection.addEventListener(events.CONNECTION_DISCONNECTED,this.#CONNECTION_DISCONNECTED.bind(this));
      this.jitsi.connection.addEventListener(events.WRONG_STATE, this.#WRONG_STATE.bind(this));
      this.jitsi.connection.addEventListener(events.DISPLAY_NAME_REQUIRED,this.#DISPLAY_NAME_REQUIRED.bind(this));
      this.jitsi.connection.connect();
      this.jitsi.conference = this.jitsi.connection.initJitsiConference(this.jitsi.payload.room,{
        openBridgeChannel: true,
        enableNoisyMicDetection: true,
        p2p: {
          enabled: false,
        },
      });
      this.jitsi.conference.setDisplayName(this.jitsi?.payload?.context?.user?.name || 'user')
      this.jitsi.user_id=this.jitsi.conference.myUserId()
      this.recorder = new Record({
        onStop(res){
          const url = res?.url
          JITSI_LOGGER.log(':::::::::::::::::url',url)
          if(url) window.open(url,'_blank')
          that.jitsi.conference.setLocalParticipantProperty('recording','false')
        },
        onStart(){
          that.jitsi.conference.setLocalParticipantProperty('recording','true')
        },
      })
    } catch(error) {
      JITSI_LOGGER.danger({error})
    }
  }
  join(){
    try {
      if(!this.is_connected) throw 'Connection does not make right now';
      this.jitsi.conference.join();
      const { events: { conference: events }, } = window.JitsiMeetJS;
      //events
      this.jitsi.conference.on(events.AUDIO_INPUT_STATE_CHANGE,this.#AUDIO_INPUT_STATE_CHANGE.bind(this));
      this.jitsi.conference.on(events.AUTH_STATUS_CHANGED,this.#AUTH_STATUS_CHANGED.bind(this));
      this.jitsi.conference.on(events.BEFORE_STATISTICS_DISPOSED,this.#BEFORE_STATISTICS_DISPOSED.bind(this));
      this.jitsi.conference.on(events.BOT_TYPE_CHANGED, this.#BOT_TYPE_CHANGED.bind(this));
      this.jitsi.conference.on(events.CONFERENCE_CREATED_TIMESTAMP,this.#CONFERENCE_CREATED_TIMESTAMP.bind(this));
      this.jitsi.conference.on(events.CONFERENCE_ERROR, this.#CONFERENCE_ERROR.bind(this));
      this.jitsi.conference.on(events.CONFERENCE_FAILED,this.#CONFERENCE_FAILED.bind(this));
      this.jitsi.conference.on(events.CONFERENCE_JOINED,this.#CONFERENCE_JOINED.bind(this));
      this.jitsi.conference.on(events.CONFERENCE_LEFT, this.#CONFERENCE_LEFT.bind(this));
      this.jitsi.conference.on(events.CONNECTION_ESTABLISHED,this.#CONFERENCE_CONNECTION_ESTABLISHED.bind(this));
      this.jitsi.conference.on(events.CONNECTION_INTERRUPTED,this.#CONNECTION_INTERRUPTED.bind(this));
      this.jitsi.conference.on(events.CONNECTION_RESTORED,this.#CONNECTION_RESTORED.bind(this));
      this.jitsi.conference.on(events.DATA_CHANNEL_OPENED,this.#DATA_CHANNEL_OPENED.bind(this));
      this.jitsi.conference.on(events.DISPLAY_NAME_CHANGED,this.#DISPLAY_NAME_CHANGED.bind(this));
      this.jitsi.conference.on(events.DOMINANT_SPEAKER_CHANGED,this.#DOMINANT_SPEAKER_CHANGED.bind(this));
      this.jitsi.conference.on(events.DTMF_SUPPORT_CHANGED,this.#DTMF_SUPPORT_CHANGED.bind(this));
      this.jitsi.conference.on(events.ENDPOINT_MESSAGE_RECEIVED,this.#ENDPOINT_MESSAGE_RECEIVED.bind(this));
      this.jitsi.conference.on(events.JVB121_STATUS, this.#JVB121_STATUS.bind(this));
      this.jitsi.conference.on(events.KICKED, this.#KICKED.bind(this));
      this.jitsi.conference.on(events.LAST_N_ENDPOINTS_CHANGED,this.#LAST_N_ENDPOINTS_CHANGED.bind(this));
      this.jitsi.conference.on(events.LOBBY_USER_JOINED,this.#LOBBY_USER_JOINED.bind(this));
      this.jitsi.conference.on(events.LOBBY_USER_LEFT, this.#LOBBY_USER_LEFT.bind(this));
      this.jitsi.conference.on(events.LOBBY_USER_UPDATED,this.#LOBBY_USER_UPDATED.bind(this));
      this.jitsi.conference.on(events.LOCK_STATE_CHANGED,this.#LOCK_STATE_CHANGED.bind(this));
      this.jitsi.conference.on(events.MEMBERS_ONLY_CHANGED,this.#MEMBERS_ONLY_CHANGED.bind(this));
      this.jitsi.conference.on(events.MESSAGE_RECEIVED, this.#MESSAGE_RECEIVED.bind(this));
      this.jitsi.conference.on(events.NOISY_MIC, this.#NOISY_MIC.bind(this));
      this.jitsi.conference.on(events.NO_AUDIO_INPUT, this.#NO_AUDIO_INPUT.bind(this));
      this.jitsi.conference.on(events.P2P_STATUS, this.#P2P_STATUS.bind(this));
      this.jitsi.conference.on(events.PARTCIPANT_FEATURES_CHANGED,this.#PARTCIPANT_FEATURES_CHANGED.bind(this));
      this.jitsi.conference.on(events.PARTICIPANT_CONN_STATUS_CHANGED,this.#PARTICIPANT_CONN_STATUS_CHANGED.bind(this));
      this.jitsi.conference.on(events.PARTICIPANT_KICKED,this.#PARTICIPANT_KICKED.bind(this));
      this.jitsi.conference.on(events.PARTICIPANT_PROPERTY_CHANGED,this.#PARTICIPANT_PROPERTY_CHANGED.bind(this));
      this.jitsi.conference.on(events.PHONE_NUMBER_CHANGED,this.#PHONE_NUMBER_CHANGED.bind(this));
      this.jitsi.conference.on(events.PRIVATE_MESSAGE_RECEIVED,this.#PRIVATE_MESSAGE_RECEIVED.bind(this));
      this.jitsi.conference.on(events.PROPERTIES_CHANGED,this.#PROPERTIES_CHANGED.bind(this));
      this.jitsi.conference.on(events.RECORDER_STATE_CHANGED,this.#RECORDER_STATE_CHANGED.bind(this));
      this.jitsi.conference.on(events.SERVER_REGION_CHANGED,this.#SERVER_REGION_CHANGED.bind(this));
      this.jitsi.conference.on(events.STARTED_MUTED, this.#STARTED_MUTED.bind(this));
      this.jitsi.conference.on(events.START_MUTED_POLICY_CHANGED,this.#START_MUTED_POLICY_CHANGED.bind(this));
      this.jitsi.conference.on(events.SUBJECT_CHANGED, this.#SUBJECT_CHANGED.bind(this));
      this.jitsi.conference.on(events.SUSPEND_DETECTED, this.#SUSPEND_DETECTED.bind(this));
      this.jitsi.conference.on(events.TALK_WHILE_MUTED, this.#TALK_WHILE_MUTED.bind(this));
      this.jitsi.conference.on(events.TRACK_ADDED, this.#TRACK_ADDED.bind(this));
      this.jitsi.conference.on(events.TRACK_AUDIO_LEVEL_CHANGED,this.#TRACK_AUDIO_LEVEL_CHANGED.bind(this));
      this.jitsi.conference.on(events.TRACK_MUTE_CHANGED,this.#TRACK_MUTE_CHANGED.bind(this));
      this.jitsi.conference.on(events.TRACK_REMOVED, this.#TRACK_REMOVED.bind(this));
      this.jitsi.conference.on(events.TRANSCRIPTION_STATUS_CHANGED,this.#TRANSCRIPTION_STATUS_CHANGED.bind(this));
      this.jitsi.conference.on(events.USER_JOINED, this.#USER_JOINED.bind(this));
      this.jitsi.conference.on(events.USER_LEFT, this.#USER_LEFT.bind(this));
      this.jitsi.conference.on(events.USER_ROLE_CHANGED,this.#USER_ROLE_CHANGED.bind(this));
      this.jitsi.conference.on(events.USER_STATUS_CHANGED,this.#USER_STATUS_CHANGED.bind(this));
      this.jitsi.conference.on(events.VIDEO_SIP_GW_AVAILABILITY_CHANGED,this.#VIDEO_SIP_GW_AVAILABILITY_CHANGED.bind(this));
      this.jitsi.conference.on(events.VIDEO_SIP_GW_SESSION_STATE_CHANGED,this.#VIDEO_SIP_GW_SESSION_STATE_CHANGED.bind(this));
      this.jitsi.conference.on(events._MEDIA_SESSION_ACTIVE_CHANGED,this.#_MEDIA_SESSION_ACTIVE_CHANGED.bind(this));
      this.jitsi.conference.on(events._MEDIA_SESSION_STARTED,this.#_MEDIA_SESSION_STARTED.bind(this));
      this.jitsi.conference.addCommandListener(this.commands.CHANNEL_ID_CHANGED,this.#CHANNEL_ID_CHANGED.bind(this));
      this.jitsi.conference.addCommandListener(this.commands.CHANNEL_DATA,this.#CHANNEL_DATA.bind(this));
      this.jitsi.conference.addCommandListener(this.commands.WHITE_BOARD_DATA,this.#WHITE_BOARD_DATA.bind(this));
      this.jitsi.conference.addCommandListener(this.commands.EMOJI,this.#EMOJI.bind(this));
    } catch(error) {
      JITSI_LOGGER.danger({error})
    }
  }
  sendMessage(text=''){
    if(!text) return;
    this.jitsi.conference.sendTextMessage(text)
  }
  setSounds(value){
    this.sounds.reaction=!!value?.reaction
    this.sounds.incoming_message=!!value?.incoming_message
    this.sounds.user_joined=!!value?.user_joined
    this.sounds.user_left=!!value?.user_left
  }
  setProfile(value){
    const name = value?.display_name
    const avatar = value?.image_url || ''
    this.jitsi.conference.setDisplayName(name)
    this.jitsi.payload.context.user.name=name
    this.jitsi.conference.setLocalParticipantProperty('avatar',avatar)
    this.jitsi.payload.context.user.avatar=avatar
  }
  #disposeTracks(){
    this.tracks.local.map((track)=>track.dispose())
  }
  #distroy(){
    this.#disposeTracks()
    if(speaker_interval) clearInterval(speaker_interval)
    if(time_interval) clearInterval(time_interval)
    if(this.is_connected){
      const { events: { connection: events }, } = window.JitsiMeetJS;
      this.jitsi.connection.removeEventListener(events.CONNECTION_ESTABLISHED,this.#CONNECTION_ESTABLISHED.bind(this));
      this.jitsi.connection.removeEventListener(events.CONNECTION_FAILED,this.#CONNECTION_FAILED.bind(this));
      this.jitsi.connection.removeEventListener(events.CONNECTION_DISCONNECTED,this.#CONNECTION_DISCONNECTED.bind(this));
      this.jitsi.connection.removeEventListener(events.WRONG_STATE, this.#WRONG_STATE.bind(this));
      this.jitsi.connection.removeEventListener(events.DISPLAY_NAME_REQUIRED,this.#DISPLAY_NAME_REQUIRED.bind(this));
    }
    if(this.is_join){
      const { events: { conference: events }, } = window.JitsiMeetJS;
      //events
      this.jitsi.conference.off(events.AUDIO_INPUT_STATE_CHANGE,this.#AUDIO_INPUT_STATE_CHANGE.bind(this));
      this.jitsi.conference.off(events.AUTH_STATUS_CHANGED,this.#AUTH_STATUS_CHANGED.bind(this));
      this.jitsi.conference.off(events.BEFORE_STATISTICS_DISPOSED,this.#BEFORE_STATISTICS_DISPOSED.bind(this));
      this.jitsi.conference.off(events.BOT_TYPE_CHANGED, this.#BOT_TYPE_CHANGED.bind(this));
      this.jitsi.conference.off(events.CONFERENCE_CREATED_TIMESTAMP,this.#CONFERENCE_CREATED_TIMESTAMP.bind(this));
      this.jitsi.conference.off(events.CONFERENCE_ERROR, this.#CONFERENCE_ERROR.bind(this));
      this.jitsi.conference.off(events.CONFERENCE_FAILED,this.#CONFERENCE_FAILED.bind(this));
      this.jitsi.conference.off(events.CONFERENCE_JOINED,this.#CONFERENCE_JOINED.bind(this));
      this.jitsi.conference.off(events.CONFERENCE_LEFT, this.#CONFERENCE_LEFT.bind(this));
      this.jitsi.conference.off(events.CONNECTION_ESTABLISHED,this.#CONFERENCE_CONNECTION_ESTABLISHED.bind(this));
      this.jitsi.conference.off(events.CONNECTION_INTERRUPTED,this.#CONNECTION_INTERRUPTED.bind(this));
      this.jitsi.conference.off(events.CONNECTION_RESTORED,this.#CONNECTION_RESTORED.bind(this));
      this.jitsi.conference.off(events.DATA_CHANNEL_OPENED,this.#DATA_CHANNEL_OPENED.bind(this));
      this.jitsi.conference.off(events.DISPLAY_NAME_CHANGED,this.#DISPLAY_NAME_CHANGED.bind(this));
      this.jitsi.conference.off(events.DOMINANT_SPEAKER_CHANGED,this.#DOMINANT_SPEAKER_CHANGED.bind(this));
      this.jitsi.conference.off(events.DTMF_SUPPORT_CHANGED,this.#DTMF_SUPPORT_CHANGED.bind(this));
      this.jitsi.conference.off(events.ENDPOINT_MESSAGE_RECEIVED,this.#ENDPOINT_MESSAGE_RECEIVED.bind(this));
      this.jitsi.conference.off(events.JVB121_STATUS, this.#JVB121_STATUS.bind(this));
      this.jitsi.conference.off(events.KICKED, this.#KICKED.bind(this));
      this.jitsi.conference.off(events.LAST_N_ENDPOINTS_CHANGED,this.#LAST_N_ENDPOINTS_CHANGED.bind(this));
      this.jitsi.conference.off(events.LOBBY_USER_JOINED,this.#LOBBY_USER_JOINED.bind(this));
      this.jitsi.conference.off(events.LOBBY_USER_LEFT, this.#LOBBY_USER_LEFT.bind(this));
      this.jitsi.conference.off(events.LOBBY_USER_UPDATED,this.#LOBBY_USER_UPDATED.bind(this));
      this.jitsi.conference.off(events.LOCK_STATE_CHANGED,this.#LOCK_STATE_CHANGED.bind(this));
      this.jitsi.conference.off(events.MEMBERS_ONLY_CHANGED,this.#MEMBERS_ONLY_CHANGED.bind(this));
      this.jitsi.conference.off(events.MESSAGE_RECEIVED, this.#MESSAGE_RECEIVED.bind(this));
      this.jitsi.conference.off(events.NOISY_MIC, this.#NOISY_MIC.bind(this));
      this.jitsi.conference.off(events.NO_AUDIO_INPUT, this.#NO_AUDIO_INPUT.bind(this));
      this.jitsi.conference.off(events.P2P_STATUS, this.#P2P_STATUS.bind(this));
      this.jitsi.conference.off(events.PARTCIPANT_FEATURES_CHANGED,this.#PARTCIPANT_FEATURES_CHANGED.bind(this));
      this.jitsi.conference.off(events.PARTICIPANT_CONN_STATUS_CHANGED,this.#PARTICIPANT_CONN_STATUS_CHANGED.bind(this));
      this.jitsi.conference.off(events.PARTICIPANT_KICKED,this.#PARTICIPANT_KICKED.bind(this));
      this.jitsi.conference.off(events.PARTICIPANT_PROPERTY_CHANGED,this.#PARTICIPANT_PROPERTY_CHANGED.bind(this));
      this.jitsi.conference.off(events.PHONE_NUMBER_CHANGED,this.#PHONE_NUMBER_CHANGED.bind(this));
      this.jitsi.conference.off(events.PRIVATE_MESSAGE_RECEIVED,this.#PRIVATE_MESSAGE_RECEIVED.bind(this));
      this.jitsi.conference.off(events.PROPERTIES_CHANGED,this.#PROPERTIES_CHANGED.bind(this));
      this.jitsi.conference.off(events.RECORDER_STATE_CHANGED,this.#RECORDER_STATE_CHANGED.bind(this));
      this.jitsi.conference.off(events.SERVER_REGION_CHANGED,this.#SERVER_REGION_CHANGED.bind(this));
      this.jitsi.conference.off(events.STARTED_MUTED, this.#STARTED_MUTED.bind(this));
      this.jitsi.conference.off(events.START_MUTED_POLICY_CHANGED,this.#START_MUTED_POLICY_CHANGED.bind(this));
      this.jitsi.conference.off(events.SUBJECT_CHANGED, this.#SUBJECT_CHANGED.bind(this));
      this.jitsi.conference.off(events.SUSPEND_DETECTED, this.#SUSPEND_DETECTED.bind(this));
      this.jitsi.conference.off(events.TALK_WHILE_MUTED, this.#TALK_WHILE_MUTED.bind(this));
      this.jitsi.conference.off(events.TRACK_ADDED, this.#TRACK_ADDED.bind(this));
      this.jitsi.conference.off(events.TRACK_AUDIO_LEVEL_CHANGED,this.#TRACK_AUDIO_LEVEL_CHANGED.bind(this));
      this.jitsi.conference.off(events.TRACK_MUTE_CHANGED,this.#TRACK_MUTE_CHANGED.bind(this));
      this.jitsi.conference.off(events.TRACK_REMOVED, this.#TRACK_REMOVED.bind(this));
      this.jitsi.conference.off(events.TRANSCRIPTION_STATUS_CHANGED,this.#TRANSCRIPTION_STATUS_CHANGED.bind(this));
      this.jitsi.conference.off(events.USER_JOINED, this.#USER_JOINED.bind(this));
      this.jitsi.conference.off(events.USER_LEFT, this.#USER_LEFT.bind(this));
      this.jitsi.conference.off(events.USER_ROLE_CHANGED,this.#USER_ROLE_CHANGED.bind(this));
      this.jitsi.conference.off(events.USER_STATUS_CHANGED,this.#USER_STATUS_CHANGED.bind(this));
      this.jitsi.conference.off(events.VIDEO_SIP_GW_AVAILABILITY_CHANGED,this.#VIDEO_SIP_GW_AVAILABILITY_CHANGED.bind(this));
      this.jitsi.conference.off(events.VIDEO_SIP_GW_SESSION_STATE_CHANGED,this.#VIDEO_SIP_GW_SESSION_STATE_CHANGED.bind(this));
      this.jitsi.conference.off(events._MEDIA_SESSION_ACTIVE_CHANGED,this.#_MEDIA_SESSION_ACTIVE_CHANGED.bind(this));
      this.jitsi.conference.off(events._MEDIA_SESSION_STARTED,this.#_MEDIA_SESSION_STARTED.bind(this));
      this.jitsi.conference.removeCommandListener(this.commands.CHANNEL_ID_CHANGED,this.#CHANNEL_ID_CHANGED.bind(this));
      this.jitsi.conference.removeCommandListener(this.commands.CHANNEL_DATA,this.#CHANNEL_DATA.bind(this));
      this.jitsi.conference.removeCommandListener(this.commands.WHITE_BOARD_DATA,this.#WHITE_BOARD_DATA.bind(this));
      this.jitsi.conference.removeCommandListener(this.commands.EMOJI,this.#EMOJI.bind(this));
    }
    MEDIA_DEVICES.off(MEDIA_DEVICES.events.videoinput_change,this.#VIDEOINPUT_DEVICES_CHANGE.bind(this))
    MEDIA_DEVICES.off(MEDIA_DEVICES.events.audioinput_change,this.#AUDIOINPUT_DEVICES_CHANGE.bind(this))
    MEDIA_DEVICES.off(MEDIA_DEVICES.events.audiooutput_change,this.#AUDIOOUTPUT_DEVICES_CHANGE.bind(this))
    PERMISSIONS.off(PERMISSIONS.events.change_mic_permission,this.#MIC_PERMISSION_CHANGE.bind(this))
    PERMISSIONS.off(PERMISSIONS.events.change_camera_permission,this.#CAMERA_PERMISSION_CHANGE.bind(this))
    document.removeEventListener('fullscreenchange',this.#FULLSCREEN_CHANGE.bind(this))
    this.#emmiting(events.distroy,{
      id: this.id,
    })
  }
  #emmiting(event={},payload={}){
    const evnt = event?.value
    const callBackName = event?.callBack
    if(evnt){
      const callBack = function (fun,data) { if(typeof fun == 'function') fun(data) }
      this.emit(evnt,payload)
      callBack(this.call_backs?.[callBackName],payload)
    }
  }
  #sound(type){
    const sound_type = sounds_type?.[type] ?? ''
    if(this.sounds[sound_type]){
      const notification = require('../../assets/sounds/notification.mp3')
      const audio = new Audio(notification)
      audio?.play()
    }
  }
  // window
  #FULLSCREEN_CHANGE(){
    this.is_full_screen=this.#el_ids.main==document?.fullscreenElement?.id
  }
  // devices
  #VIDEOINPUT_DEVICES_CHANGE(devices){
    const deviceid = this.video.camera_id
    const index = devices.findIndex(device=>device.deviceId==deviceid)
    if(index>-1) return;
    if(_.isEmpty(devices)) return;
    this.video.camera_id = devices[0].deviceId
    this.cameraTrack()
  }
  #AUDIOINPUT_DEVICES_CHANGE(devices){
    const deviceid = this.audio.mic_id
    const index = devices.findIndex(device=>device.deviceId==deviceid)
    if(index>-1) return;
    if(_.isEmpty(devices)) return;
    this.audio.mic_id = devices[0].deviceId
    this.audioTrack()
  }
  #AUDIOOUTPUT_DEVICES_CHANGE(devices){
    const deviceid = this.audio.speaker_id
    const index = devices.findIndex(device=>device.deviceId==deviceid)
    if(index>-1) return;
    if(_.isEmpty(devices)) return;
    this.audio.speaker_id = devices[0].deviceId
  }
  // permissions
  #MIC_PERMISSION_CHANGE(permission){
    this.audio.mic_permission=permission
    if(permission=='denied'){
      this.leave()
    }
  }
  #CAMERA_PERMISSION_CHANGE(permission){
    this.video.camera_permission=permission
    if(permission=='denied'){
      this.videoMute()
    }
  }
  // connection
  // events
  #CONNECTION_ESTABLISHED(id) {
    JITSI_LOGGER.event("Jitsi::CONNECTION::CONNECTION_ESTABLISHED", {
      id,
    });
    this.is_connected = true;
  }
  #CONNECTION_FAILED(errorCode, type) {
    JITSI_LOGGER.event("Jitsi::CONNECTION::CONNECTION_FAILED", {
      errorCode,
      type,
    });
    const { errors: { connection: errors }, } = window.JitsiMeetJS;
    switch (errorCode) {
      case errors.OTHER_ERROR:
        this.#OTHER_ERROR(type);
        break;
      case errors.PASSWORD_REQUIRED:
        this.#PASSWORD_REQUIRED(type);
        break;
      case errors.CONNECTION_DROPPED_ERROR:
        this.#CONNECTION_DROPPED_ERROR(type);
        break;
      case errors.SERVER_ERROR:
        this.#SERVER_ERROR(type);
        break;
      default:
        JITSI_LOGGER.info("error are not in JitsiMeetJS", { errors });
        break;
    }
  }
  #CONNECTION_DISCONNECTED() {
    JITSI_LOGGER.event("Jitsi::CONNECTION::CONNECTION_DISCONNECTED");
    this.is_connected=false
  }
  #WRONG_STATE() {
    JITSI_LOGGER.event("Jitsi::CONNECTION::WRONG_STATE");
  }
  #DISPLAY_NAME_REQUIRED(...args) {
    JITSI_LOGGER.event("Jitsi::CONNECTION::DISPLAY_NAME_REQUIRED", ...args);
  }

  //--error--
  #CONNECTION_DROPPED_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONNECTION::CONNECTION_DROPPED_ERROR");
    this.#distroy()
  }
  #PASSWORD_REQUIRED() {
    JITSI_LOGGER.danger("Jitsi::CONNECTION::PASSWORD_REQUIRED");
  }
  #SERVER_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONNECTION::SERVER_ERROR");
    this.#distroy()
  }
  #OTHER_ERROR(type) {
    JITSI_LOGGER.danger("Jitsi::CONNECTION::OTHER_ERROR",{
      type,
    });
    if(type=='giving-up'){
      this.#distroy()
    }
  }
  // conference
  //----command----
  #CHANNEL_ID_CHANGED(values) {
    JITSI_LOGGER.event(`Jitsi::CONFERENCE::${this.commands.CHANNEL_ID_CHANGED}`, values);
    const { value } = values;
    this.mattermost.channel_id = value;
  }
  #CHANNEL_DATA(values) {
    JITSI_LOGGER.event(`Jitsi::CONFERENCE::${this.commands.CHANNEL_DATA}`, values);
    const { attributes: { channel_id, type }, } = values;
    this.mattermost.channel_id = channel_id || this.mattermost.channel_id;
    this.mattermost.type = type || this.mattermost.type;
  }
  #WHITE_BOARD_DATA(values){
    JITSI_LOGGER.event(`Jitsi::CONFERENCE::${this.commands.WHITE_BOARD_DATA}`, values);
    const { attributes: { event, data, user_id }, } = values;
    if(user_id==this.jitsi.user_id) return;
    HELPER_FUNCTIONS.sleep(this.white_board?0:0.5 * 1000)
    .then(()=>{
      this.white_board.recieveData(event,JSON.parse(data))
    })
  }
  #EMOJI(values) {
    JITSI_LOGGER.event(`Jitsi::CONFERENCE::${this.commands.EMOJI}`, values);
    const { attributes: { emoji, participant_id }, } = values;
    if(reaction_interval) clearTimeout(reaction_interval)
    this.reaction.emoji=emoji
    this.reaction.id=participant_id
    this.#emmiting(events.reaction,this.reaction)
    this.#sound(sounds_type.reaction)
    reaction_interval = setTimeout(()=>{
      this.reaction.emoji=''
      this.reaction.id=''
    }, 3 * 1000)
  }
  //----conference----
  #CONFERENCE_CREATED_TIMESTAMP(timestamp) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONFERENCE_CREATED_TIMESTAMP", {
      timestamp,
    });
    let that = this
    this.jitsi.timestamp=timestamp
    time_interval = setInterval(()=>{
      that.time=Math.ceil((new Date().getTime() - timestamp) / 1000)
    },1 * 1000)
  }
  #CONFERENCE_ERROR(errorCode) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONFERENCE_ERROR", {
      errorCode,
    });
    const { errors: { conference: errors }, } = window.JitsiMeetJS;
    switch (errorCode) {
      case errors.AUTHENTICATION_REQUIRED:
        this.#AUTHENTICATION_REQUIRED();
        break;
      case errors.CHAT_ERROR:
        this.#CHAT_ERROR();
        break;
      case errors.CONFERENCE_ACCESS_DENIED:
        this.#CONFERENCE_ACCESS_DENIED();
        break;
      case errors.CONFERENCE_DESTROYED:
        this.#CONFERENCE_DESTROYED();
        break;
      case errors.CONFERENCE_MAX_USERS:
        this.#CONFERENCE_MAX_USERS();
        break;
      case errors.CONNECTION_ERROR:
        this.#CONNECTION_ERROR();
        break;
      case errors.FOCUS_DISCONNECTED:
        this.#FOCUS_DISCONNECTED();
        break;
      case errors.FOCUS_LEFT:
        this.#FOCUS_LEFT();
        break;
      case errors.GRACEFUL_SHUTDOWN:
        this.#GRACEFUL_SHUTDOWN();
        break;
      case errors.ICE_FAILED:
        this.#ICE_FAILED();
        break;
      case errors.INCOMPATIBLE_SERVER_VERSIONS:
        this.#INCOMPATIBLE_SERVER_VERSIONS();
        break;
      case errors.MEMBERS_ONLY_ERROR:
        this.#MEMBERS_ONLY_ERROR();
        break;
      case errors.NOT_ALLOWED_ERROR:
        this.#NOT_ALLOWED_ERROR();
        break;
      case errors.OFFER_ANSWER_FAILED:
        this.#OFFER_ANSWER_FAILED();
        break;
      case errors.PASSWORD_NOT_SUPPORTED:
        this.#PASSWORD_NOT_SUPPORTED();
        break;
      case errors.PASSWORD_REQUIRED:
        this.#CONFERENCE_PASSWORD_REQUIRED();
        break;
      case errors.RESERVATION_ERROR:
        this.#RESERVATION_ERROR();
        break;
      case errors.VIDEOBRIDGE_NOT_AVAILABLE:
        this.#VIDEOBRIDGE_NOT_AVAILABLE();
        break;
      default:
        JITSI_LOGGER.info("error are not in JitsiMeetJS");
        break;
    }
  }
  #CONFERENCE_FAILED(errorCode) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONFERENCE_FAILED", {
      errorCode,
    });
    const { errors: { conference: errors }, } = window.JitsiMeetJS;
    switch (errorCode) {
      case errors.AUTHENTICATION_REQUIRED:
        this.#AUTHENTICATION_REQUIRED();
        break;
      case errors.CHAT_ERROR:
        this.#CHAT_ERROR();
        break;
      case errors.CONFERENCE_ACCESS_DENIED:
        this.#CONFERENCE_ACCESS_DENIED();
        break;
      case errors.CONFERENCE_DESTROYED:
        this.#CONFERENCE_DESTROYED();
        break;
      case errors.CONFERENCE_MAX_USERS:
        this.#CONFERENCE_MAX_USERS();
        break;
      case errors.CONNECTION_ERROR:
        this.#CONNECTION_ERROR();
        break;
      case errors.FOCUS_DISCONNECTED:
        this.#FOCUS_DISCONNECTED();
        break;
      case errors.FOCUS_LEFT:
        this.#FOCUS_LEFT();
        break;
      case errors.GRACEFUL_SHUTDOWN:
        this.#GRACEFUL_SHUTDOWN();
        break;
      case errors.ICE_FAILED:
        this.#ICE_FAILED();
        break;
      case errors.INCOMPATIBLE_SERVER_VERSIONS:
        this.#INCOMPATIBLE_SERVER_VERSIONS();
        break;
      case errors.MEMBERS_ONLY_ERROR:
        this.#MEMBERS_ONLY_ERROR();
        break;
      case errors.NOT_ALLOWED_ERROR:
        this.#NOT_ALLOWED_ERROR();
        break;
      case errors.OFFER_ANSWER_FAILED:
        this.#OFFER_ANSWER_FAILED();
        break;
      case errors.PASSWORD_NOT_SUPPORTED:
        this.#PASSWORD_NOT_SUPPORTED();
        break;
      case errors.PASSWORD_REQUIRED:
        this.#CONFERENCE_PASSWORD_REQUIRED();
        break;
      case errors.RESERVATION_ERROR:
        this.#RESERVATION_ERROR();
        break;
      case errors.VIDEOBRIDGE_NOT_AVAILABLE:
        this.#VIDEOBRIDGE_NOT_AVAILABLE();
        break;
      default:
        JITSI_LOGGER.info("error are not in JitsiMeetJS");
        break;
    }
  }
  #CONFERENCE_JOINED() {
    JITSI_LOGGER.event('Jitsi::CONFERENCE::CONFERENCE_JOINED')
    let that = this
    this.is_join = true;
    this.jitsi.conference.setLocalParticipantProperty('avatar',this.jitsi?.payload?.context?.user?.avatar || '')
    HELPER_FUNCTIONS.sleep(0.5 * 1000)
    .then(()=>{
      that.white_board = new Whiteboard({
        el_id: that.#el_ids.white_board,
        canvas_id: that.#el_ids.white_board_canvas,
        user_id: that.jitsi.user_id,
        callBacks: {
          onSyncData(message){
            that.jitsi.conference.broadcastEndpointMessage({
              event: message.event,
              data: message.data,
            })
          }
        },
      })
    })
  }
  #CONFERENCE_LEFT() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONFERENCE_LEFT");
    this.is_join=false
  }
  //------------------

  //----connection----
  #CONFERENCE_CONNECTION_ESTABLISHED() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONNECTION_ESTABLISHED");
  }
  #CONNECTION_INTERRUPTED() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONNECTION_INTERRUPTED");
  }
  #CONNECTION_RESTORED() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::CONNECTION_RESTORED");
  }
  //------------------

  //----lobby----
  #LOBBY_USER_JOINED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::LOBBY_USER_JOINED", {
      ...args,
    });
  }
  #LOBBY_USER_LEFT(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::LOBBY_USER_LEFT", {
      ...args,
    });
  }
  #LOBBY_USER_UPDATED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::LOBBY_USER_UPDATED", {
      ...args,
    });
  }
  //-------------

  //----participant----
  #PARTCIPANT_FEATURES_CHANGED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PARTCIPANT_FEATURES_CHANGED", { ...args });
  }
  #PARTICIPANT_CONN_STATUS_CHANGED(id, status) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PARTICIPANT_CONN_STATUS_CHANGED", { id, status });
  }
  #PARTICIPANT_KICKED(JitsiParticipant, removedJitsiParticipant) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PARTICIPANT_KICKED", {
      JitsiParticipant,
      removedJitsiParticipant,
    });
  }
  #PARTICIPANT_PROPERTY_CHANGED(JitsiParticipant, key) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PARTICIPANT_PROPERTY_CHANGED", { JitsiParticipant, key });
    const index = this.participants.findIndex(item=>item._id==JitsiParticipant._id)
    const value = JitsiParticipant._properties[key];
    if (!this.is_conference && key === "bye") {
      this.leave();
    } else if (index>-1) {
      this.participants[index]._properties[key] = value
    }
  }
  //-------------------

  //----track----
  #TRACK_ADDED(JitsiTrack) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::TRACK_ADDED", { JitsiTrack },JitsiTrack.isLocal());
    if (JitsiTrack.isLocal()) {
      const { events: { track: events }, } = window.JitsiMeetJS;
      const { errors: { track: errors }, } = window.JitsiMeetJS;
      JitsiTrack.once(events.LOCAL_TRACK_STOPPED,(reason)=>{
        if(errors?.SCREENSHARING_USER_CANCELED==reason){
          if(JitsiTrack.type=='video') this.stopShareScreen()
          if(JitsiTrack.type=='audio') this.audioTrack()
        }
      })
      if(JitsiTrack.type=='audio') this.recorder.addStream(JitsiTrack.stream)
      setTimeout(()=>{this.tracks.local.push(JitsiTrack)},0.5*1000)
    } else {
      const participantId = JitsiTrack.getParticipantId();
      const index = this.participants.findIndex(item=>item._id==participantId)
      if (index>-1) {
        this.participants[index]._tracks.push(JitsiTrack);
      }
    }
  }
  #TRACK_AUDIO_LEVEL_CHANGED(participantId, audioLevel) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::TRACK_AUDIO_LEVEL_CHANGED", {
      participantId,
      audioLevel,
    });
  }
  #TRACK_MUTE_CHANGED(JitsiTrack) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::TRACK_MUTE_CHANGED", {
      JitsiTrack,
    });
  }
  async #TRACK_REMOVED(JitsiTrack) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::TRACK_REMOVED", {
      JitsiTrack,
    },JitsiTrack.isLocal(),JitsiTrack.type,this.tracks.local.length);
    if (JitsiTrack.isLocal()) {
      const index = this.tracks.local.findIndex(track=>track?.type==JitsiTrack.type);
      await this.tracks.local[index].dispose()
      this.tracks.local.splice(index, 1);
      if(JitsiTrack.type=='audio') this.recorder.removeStream(JitsiTrack.stream)
    } else {
      const participantId = JitsiTrack.getParticipantId();
      const index = this.participants.findIndex(item=>item._id==participantId)
      if (index>-1) {
        const newTracks = this.participants[index]._tracks.filter((track) => track.getId() === JitsiTrack.getId());
        this.participants[index]._tracks = newTracks;
      }
    }
  }
  //-------------

  //----user----
  #USER_JOINED(id, JitsiParticipant) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::USER_JOINED", { id, JitsiParticipant });
    const index = this.participants.findIndex(item=>item._id==id)
    if (index==-1) {
      const data = {
        ...JitsiParticipant,
        _properties: {
          ...JitsiParticipant._properties,
          isVideoMuted: 'false',
          is_video_muted: false,
          is_rise_hand: false,
          is_mirror_video: false,
          audioLevel: 100,
          emoji: '',
          isHold: 'false',
          avatar: '',
          speaker_stats: 0,
          recording: 'false',
        }
      }
      this.participants.push(data)
      this.#emmiting(events.userjoined,data)
      this.#sound(sounds_type.user_joined)
    }
    if(this.participants.length>=4) this.view = this.views.speaker.value
  }
  #USER_LEFT(id, JitsiParticipant) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::USER_LEFT", {
      id,
      JitsiParticipant,
    });
    const index = this.participants.findIndex(item=>item._id==id)
    this.#emmiting(events.userleft,this.participants[index])
    this.participants.splice(index,1)
    this.#sound(sounds_type.user_left)
    if(!this.is_conference && this.participants.length === 0){
      this.leave()
    }
  }
  #USER_ROLE_CHANGED(id, role) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::USER_ROLE_CHANGED", {
      id,
      role,
    });
    const index = this.participants.findIndex(item=>item._id==id)
    if(index==-1) return;
    this.participants[index]._role=role
  }
  #USER_STATUS_CHANGED(id, status) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::USER_STATUS_CHANGED", {
      id,
      status,
    });
    const index = this.participants.findIndex(item=>item._id==id)
    if(index==-1) return;
    this.participants[index]._status=status
  }
  //------------

  //----video sip----
  #VIDEO_SIP_GW_AVAILABILITY_CHANGED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::VIDEO_SIP_GW_AVAILABILITY_CHANGED", {
      ...args,
    });
  }
  #VIDEO_SIP_GW_SESSION_STATE_CHANGED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::VIDEO_SIP_GW_SESSION_STATE_CHANGED", {
      ...args,
    });
  }
  //-----------------

  //----media session----
  #_MEDIA_SESSION_ACTIVE_CHANGED(JingleSessionPC) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::_MEDIA_SESSION_ACTIVE_CHANGED", {
      JingleSessionPC,
    });
  }
  #_MEDIA_SESSION_STARTED(JingleSessionPC) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::_MEDIA_SESSION_STARTED", {
      JingleSessionPC,
    });
  }
  //---------------------

  //----others----
  #AUDIO_INPUT_STATE_CHANGE(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::AUDIO_INPUT_STATE_CHANGE", {
      ...args,
    });
  }
  #AUTH_STATUS_CHANGED(isAuthEnabled, authIdentity) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::AUTH_STATUS_CHANGED", {
      isAuthEnabled,
      authIdentity,
    });
  }
  #BEFORE_STATISTICS_DISPOSED() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::BEFORE_STATISTICS_DISPOSED");
  }
  #BOT_TYPE_CHANGED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::BOT_TYPE_CHANGED", {
      ...args,
    });
  }
  #DATA_CHANNEL_OPENED() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::DATA_CHANNEL_OPENED");
  }
  #DISPLAY_NAME_CHANGED(id, displayName) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::DISPLAY_NAME_CHANGED", { id, displayName });
    const index = this.participants.findIndex(item=>item._id==id)
    if (index>-1) {
      this.participants[index]._displayName = displayName;
    }
  }
  #DOMINANT_SPEAKER_CHANGED(id) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::DOMINANT_SPEAKER_CHANGED", {
      id,
    });
    this.speaker_id=id
    clearInterval(speaker_interval)
    speaker_interval = setInterval(()=>{
      if(id==this.jitsi.user_id){
        this.audio.speaker_stats=this.audio.speaker_stats+1
        this.jitsi.conference.setLocalParticipantProperty('speaker_stats',this.audio.speaker_stats)
      }
    },1 * 1000)
  }
  #DTMF_SUPPORT_CHANGED(supports) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::DTMF_SUPPORT_CHANGED", {
      supports,
    });
  }
  #ENDPOINT_MESSAGE_RECEIVED(JitsiParticipant, properties) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::ENDPOINT_MESSAGE_RECEIVED", {JitsiParticipant,properties,});
    const { event, data, } = properties;
    if(JitsiParticipant._id==this.jitsi.user_id) return;
    HELPER_FUNCTIONS.sleep(this.white_board?0:0.5 * 1000)
    .then(()=>{
      this.white_board.recieveData(event,JSON.parse(data))
    })
  }
  #JVB121_STATUS(status) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::JVB121_STATUS", {
      status,
    });
  }
  #KICKED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::KICKED", {
      ...args,
    });
  }
  #LAST_N_ENDPOINTS_CHANGED(leavingEndpointIds) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::LAST_N_ENDPOINTS_CHANGED", {
      leavingEndpointIds,
    });
  }
  #LOCK_STATE_CHANGED(state) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::LOCK_STATE_CHANGED", {
      state,
    });
  }
  #MEMBERS_ONLY_CHANGED(isMembersOnlyChanged) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::MEMBERS_ONLY_CHANGED", {
      isMembersOnlyChanged,
    });
  }
  #MESSAGE_RECEIVED(id, text, ts) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::MESSAGE_RECEIVED", {
      id,
      text,
      ts,
    });
    const data = {
      sender: id,
      text: text,
    }
    if(id!==this.jitsi.user_id){
      this.#emmiting(events.incomingmessage,data)
      this.#sound(sounds_type.incoming_message)
    }
    this.#emmiting(events.message,data)
    this.messages.push(data)
  }
  #NOISY_MIC(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::NOISY_MIC", {
      ...args,
    });
  }
  #NO_AUDIO_INPUT(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::NO_AUDIO_INPUT", {
      ...args,
    });
  }
  #P2P_STATUS(JitsiConference) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::P2P_STATUS", {
      JitsiConference,
    });
  }
  #PHONE_NUMBER_CHANGED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PHONE_NUMBER_CHANGED", {
      ...args,
    });
  }
  #PRIVATE_MESSAGE_RECEIVED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PRIVATE_MESSAGE_RECEIVED", {
      ...args,
    });
  }
  #PROPERTIES_CHANGED(properties) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::PROPERTIES_CHANGED", {
      properties,
    });
  }
  #RECORDER_STATE_CHANGED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::RECORDER_STATE_CHANGED", {
      ...args,
    });
  }
  #SERVER_REGION_CHANGED() {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::SERVER_REGION_CHANGED");
  }
  #STARTED_MUTED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::STARTED_MUTED", {
      ...args,
    });
  }
  #START_MUTED_POLICY_CHANGED({ audio, video }) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::START_MUTED_POLICY_CHANGED", {
      audio,
      video,
    });
  }
  #SUBJECT_CHANGED(subject) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::SUBJECT_CHANGED", {
      subject,
    });
  }
  #SUSPEND_DETECTED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::SUSPEND_DETECTED", {
      ...args,
    });
  }
  #TALK_WHILE_MUTED(...args) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::TALK_WHILE_MUTED", {
      ...args,
    });
  }
  #TRANSCRIPTION_STATUS_CHANGED(status) {
    JITSI_LOGGER.event("Jitsi::CONFERENCE::TRANSCRIPTION_STATUS_CHANGED", {
      status,
    });
  }
  //--------------

  //--errors--
  #AUTHENTICATION_REQUIRED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::AUTHENTICATION_REQUIRED");
  }
  #CHAT_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::CHAT_ERROR");
  }
  #CONFERENCE_ACCESS_DENIED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::CONFERENCE_ACCESS_DENIED");
  }
  #CONFERENCE_DESTROYED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::CONFERENCE_DESTROYED");
  }
  #CONFERENCE_MAX_USERS() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::CONFERENCE_MAX_USERS");
  }
  #CONNECTION_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::CONNECTION_ERROR");
  }
  #FOCUS_DISCONNECTED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::FOCUS_DISCONNECTED");
  }
  #FOCUS_LEFT() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::FOCUS_LEFT");
  }
  #GRACEFUL_SHUTDOWN() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::GRACEFUL_SHUTDOWN");
  }
  #ICE_FAILED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::ICE_FAILED");
  }
  #INCOMPATIBLE_SERVER_VERSIONS() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::INCOMPATIBLE_SERVER_VERSIONS");
  }
  #MEMBERS_ONLY_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::MEMBERS_ONLY_ERROR");
  }
  #NOT_ALLOWED_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::NOT_ALLOWED_ERROR");
  }
  #OFFER_ANSWER_FAILED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::OFFER_ANSWER_FAILED");
  }
  #PASSWORD_NOT_SUPPORTED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::PASSWORD_NOT_SUPPORTED");
  }
  #CONFERENCE_PASSWORD_REQUIRED() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::PASSWORD_REQUIRED");
  }
  #RESERVATION_ERROR() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::RESERVATION_ERROR");
  }
  #VIDEOBRIDGE_NOT_AVAILABLE() {
    JITSI_LOGGER.danger("Jitsi::CONFERENCE::VIDEOBRIDGE_NOT_AVAILABLE");
  }
}
export default RunningCall