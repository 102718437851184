<template>
  <div 
    class="main2 dialer-login-flex new-bg new-bg-v2"
    :class="{
      'dashboard-iframe':['dashboard','conference'].includes(screen)
    }"
  >
    <div class="d-flex align-items-center justify-content-center w-100 vh-100">
      <div 
        class="dialer-login"
        :class="{
          'flex-column': $route.name=='signup',
          'inside_conference': $route.name=='conference' && screen=='callstartup',
        }"
      >
        <div 
          class="dialer-login-inner d-flex flex-column"
          :class="{
            'dialer-signup-form v2': $route.name=='signup' || $route.name=='invitation' || $route.name=='dashboard'
          }"
        >
          <b-alert 
            :show="!isChrome" 
            variant="info" 
            dismissible 
            class="w-100 position-fixed top-0 m-0 rounded-0 dismissibleAlert" 
            style="z-index: 2000;"
          >
            Please use chrome browser for best experience.
          </b-alert>
          <div>
            <router-view @screen="screen=$event" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DEVICE_INFO } from '@/store/constants'
import { mapGetters } from 'vuex'
export default {
  name: 'AuthorizationLayout',
  data(){
    return {
      screen: '',
    }
  },
  computed: {
    ...mapGetters([
      GET_DEVICE_INFO
    ]),
    isChrome(){ return this.GET_DEVICE_INFO?.browser?.chrome },
  },
}
</script>

