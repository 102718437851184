import { JITSI_LOGGER } from "@/service/index"

/* eslint-disable no-cond-assign */
class Record {
  recorder = null
  track = {
    video: null,
    audio: null,
  }
  final_stream = null
  is_started = false
  is_recorded = false
  onStop = null
  onStart = null
  get isRecorded(){ return this.is_recorded }
  constructor(props){
    this.onStop=props?.onStop
    this.onStart=props?.onStart
    this.final_stream = new MediaStream()
    this.recorder = new window.RecordRTC(this.final_stream, {
      type: 'video'
    });
  }
  addStream(stream){
    let that = this
    stream?.getTracks?.()?.forEach?.((track)=>that.final_stream.addTrack(track))
  }
  removeStream(stream){
    let that = this
    const tracks_id = that.final_stream.getTracks()?.map?.(track=>track.id)
    stream?.getTracks?.()?.forEach?.((track)=>tracks_id.includes(track.id) ? that.final_stream.removeTrack(track) : '')
  }
  disposeTracks(){
    if(this.track.video) {
      this.track.video.dispose()
      this.removeStream(this.track.video.stream)
      this.track.video = null
    }
    if(this.track.audio) {
      this.track.audio.dispose()
      this.removeStream(this.track.audio.stream)
      this.track.audio = null
    }
  }
  async start(){
    if(this.is_started) return;
    try {
      const desktop = await window.JitsiMeetJS.createLocalTracks({
        devices: ["desktop"],
        minFps: 30,
        maxFps: 60,
      });
      const desktop_video = desktop.find((track)=>track.type=='video');
      const desktop_audio = desktop.find((track)=>track.type=='audio');
      if(!desktop_video || !desktop_audio) throw new Error('Please select audio also')
      this.track.video=desktop_video
      this.track.audio=desktop_audio
      const events = window?.JitsiMeetJS?.events?.track ?? {};
      this.track.video.once(events.LOCAL_TRACK_STOPPED,this.stop)
      this.track.audio.once(events.LOCAL_TRACK_STOPPED,this.stop)
      this.addStream(desktop_video.stream)
      this.addStream(desktop_audio.stream)
      this.is_recorded = true;
      this.is_started = true;
      this.recorder.startRecording();
      this.onStart?.()
    } catch(ex) {
      JITSI_LOGGER.danger(ex)
      this.is_started = false;
      this.disposeTracks()
      this.recorder?.reset?.()
    }
  }
  stop(){
    let that = this
    if(!this.is_started) return;
    this.recorder.stopRecording(function() {
      that.is_started = false;
      let blob = that.recorder?.getBlob?.();
      that.onStop?.({
        blob,
        url: blob ? URL.createObjectURL(blob) : '',
      })
      that.disposeTracks()
      that.recorder.reset()
    });
  }
  save(file_name='test'){
    if(this.is_recorded && !this.is_started){
      this.recorder.save(file_name)
    }
  }
}
export default Record