
import browserID from 'browser-id'
import UAParser from 'ua-parser-js'
export const DEVICE_INFO = {
  init(){
    try {
      const ua = navigator?.userAgent ?? '';
      this.id = browserID()
      this.tab_id = window?.navigation?.currentEntry?.id ?? ''
      // Intl.DateTimeFormat().resolvedOptions().timeZone;
      const av = navigator?.appVersion ?? '';
      const parser = new UAParser(ua);
      const parserResults = parser.getResult();
      console.log('parserResults',parserResults);
      // device
      if(/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) this.device.tablet=true
      else if(/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) this.device.mobile=true
      else this.device.desktop=true
      // browser
      if(ua.toLowerCase().includes("edge")) this.browser.ms_edge=true
      else if(ua.toLowerCase().includes("edg/")) this.browser.edge=true
      else if(ua.toLowerCase().includes("opr") && !!window.opr) this.browser.opera=true
      else if(ua.toLowerCase().includes("chrome") && !!window.chrome) this.browser.chrome=true
      else if(ua.toLowerCase().includes("trident")) this.browser.ms_ie=true
      else if(ua.toLowerCase().includes("firefox")) this.browser.firefox=true
      else if(ua.toLowerCase().includes("safari")) this.browser.safari=true
      // os
      if(av.includes("Win")) this.os.window=true
      else if(av.includes("Mac")) this.os.mac=true
      else if(av.includes("X11")) this.os.unix=true
      else if(av.includes("Linux")) this.os.linux=true
      else if(ua.toLowerCase().includes("android")) this.os.android=true
    } catch (error) {
      console.log('DEVICE_INFO',error)
    } finally {
      if(process.env.NODE_ENV!='production'){ 
        window.voip_app.DEVICE_INFO = this 
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  id: '',
  tab_id: '',
  device: {
    tablet: false,
    mobile: false,
    desktop: false,
  },
  browser: {
    ms_edge: false,
    edge: false,
    opera: false,
    chrome: false,
    ms_ie: false,
    firefox: false,
    safari: false,
  },
  os: {
    window: false,
    mac: false,
    unix: false,
    linux: false,
    android: false,
  },
}