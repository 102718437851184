import parsePhoneNumber from "libphonenumber-js"
import parseMin from 'libphonenumber-js/min'
import parseMax from 'libphonenumber-js/max'
import parseMobile from 'libphonenumber-js/mobile'

export const HELPER_FUNCTIONS = {
  getParsePhoneNumber(type=''){
    if(type=='max'){
      return parseMax
    } else if(type=='min'){
      return parseMin
    } else if(type=='mobile'){
      return parseMobile
    } else {
      return parsePhoneNumber
    }
  },
  validPhoneNumber(value,country='',type=''){
    const phoneNumber = HELPER_FUNCTIONS.getParsePhoneNumber(type)(value,country)
    if(phoneNumber && phoneNumber.isValid()){
      if(country){
        return country==phoneNumber.country
      } else {
        return true
      }
    } else {
      return false
    }
    // const as_you_type = new AsYouType(country ?? '')
    // as_you_type?.input?.(value)
    // return !!as_you_type?.isValid?.()
  },
  getNumberFormated(number,country,format='',type){
    const phoneNumber = HELPER_FUNCTIONS.getParsePhoneNumber(type)(number, country)
    if (phoneNumber && phoneNumber.isValid()) {
      if(format=='INTERNATIONAL') {
        return phoneNumber?.format?.("INTERNATIONAL")
      } else if(format=='NATIONAL') {
        return phoneNumber?.format?.("NATIONAL")
      } else if(format=='OBJECT') {
        return phoneNumber
      } else {
        return phoneNumber.number
      }
    } else {
      number
    }
  },
  generateID(){
    return `${Math.random().toString(36).substr(2, 9)}${new Date().getTime()}${Math.random().toString(36).substr(2, 9)}`
  },
  /**
   *
   * sleep
   * @param {Number} ms // milliseconds
   * @param {Any} payload // optional
   * @return {Promise} 
   */
  sleep(ms=1 * 1000,payload={}) { return new Promise(resolve => setTimeout(()=>{ resolve(payload) }, ms)); },
  /**
   *
   * exit the full screen
   * @param {{data: Object, }} message
   * @return {Object} 
   */
  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  },
  /**
   *
   * make the element on full screen
   * @param {Object} elem
   */
  openFullscreen(elem) {
    if (typeof elem === 'object') {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }   
    }
  },
}