const Emitter = require('component-emitter')
import { PERMISSIONS } from './permissions'
import { DEVICES_LOGGER } from '../service/index'
const events = {
  devices_change: 'devices_change',
  videoinput_change: 'videoinput_change',
  audioinput_change: 'audioinput_change',
  audiooutput_change: 'audiooutput_change',
  camera_id_change: 'camera_id_change',
  mic_id_change: 'mic_id_change',
  speaker_id_change: 'speaker_id_change',
}
export const MEDIA_DEVICES = {
  list: [],
  camera_id: '',
  speaker_id: '',
  mic_id: '',

  set cameraId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.videoinputs.findIndex(item=>item.deviceId==value)==-1) throw 'id not extis in list'
    this.camera_id=value
    this.emit(this.events.camera_id_change,this.camera_id);
  },
  set micId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.audioinputs.findIndex(item=>item.deviceId==value)==-1) throw 'id not extis in list'
    this.mic_id=value
    this.emit(this.events.mic_id_change,this.mic_id);
  },
  set speakerId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.audiooutputs.findIndex(item=>item.deviceId==value)==-1) throw 'id not extis in list'
    this.speaker_id=value
    this.emit(this.events.speaker_id_change,this.speaker_id);
  },
  
  get videoinputs(){ return this.list.filter((device) => device.kind === "videoinput") },
  get audioinputs(){ return this.list.filter((device) => device.kind === "audioinput"); },
  get audiooutputs(){ return this.list.filter((device) => device.kind === "audiooutput"); },
  
  
  get selectedCameraDevice(){ return this.videoinputs.find(item=>item.deviceId==this.camera_id) },
  get selectedSpeakerDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.speaker_id) },
  get selectedMicDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.mic_id) },
  
  get events(){ return events },
  
  init(){
    try {
      Emitter(this)
      if(navigator?.mediaDevices?.ondevicechange !== undefined){
        navigator.mediaDevices.ondevicechange = this.devicesChanged.bind(this)
      }
      this.devicesChanged()
      PERMISSIONS.on(PERMISSIONS.events.change_camera_permission,this.devicesChanged.bind(this))
      PERMISSIONS.on(PERMISSIONS.events.change_mic_permission,this.devicesChanged.bind(this))
    } catch (error){
      console.log('MEDIA_DEVICES',error)
    } finally {
      if(process.env.NODE_ENV!='production'){
        window.voip_app.MEDIA_DEVICES = this
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  devicesChanged(){
    let that = this
    navigator?.mediaDevices?.enumerateDevices?.().then((devices)=>{
      const videoinputs = that.list.filter((device) => device.kind === "videoinput");
      const audioinputs = that.list.filter((device) => device.kind === "audioinput");
      const audiooutputs = that.list.filter((device) => device.kind === "audiooutput");
      that.list=devices
      that.emit(that.events.devices_change,that.list);
      if(videoinputs.length!=that.videoinputs.length){
        that.emit(that.events.videoinput_change,that.videoinputs);
      }
      if(audioinputs.length!=that.audioinputs.length){
        that.emit(that.events.audioinput_change,that.audioinputs);
      }
      if(audiooutputs.length!=that.audiooutputs.length){
        that.emit(that.events.audiooutput_change,that.audiooutputs);
      }
      if(!that.selectedCameraDevice){
        that.cameraId = that.videoinputs?.[0]?.deviceId ?? '';
      }
      if(!that.selectedMicDevice){
        that.micId = that.audioinputs?.[0]?.deviceId ?? '';
      }
      if(!that.selectedSpeakerDevice){
        that.speakerId = that.audiooutputs?.[0]?.deviceId ?? '';
      }
    }).catch((ex)=>{
      DEVICES_LOGGER.danger({ex})
      throw new Error(ex.message)
    })
  }
}