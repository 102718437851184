export const GET_USER = 'GET_USER';
export const GET_USER_DIALER_SERVER = 'GET_USER_DIALER_SERVER';
export const GET_USER_VB_TOKEN = 'GET_USER_VB_TOKEN';
export const GET_USER_ACCOUNTCODE = 'GET_USER_ACCOUNTCODE';
export const GET_USER_USERNAME = 'GET_USER_USERNAME';
export const GET_IS_LOGGED_IN = 'GET_IS_LOGGED_IN';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_CP_DOMAIN = 'GET_CP_DOMAIN';
export const GET_CP_DEVICE_INFO = 'GET_CP_DEVICE_INFO';
export const GET_IS_MAC = 'GET_IS_MAC';
export const GET_IS_RECAPTCHA_ENABLED = 'GET_IS_RECAPTCHA_ENABLED';
export const GET_DEVICE_ID = 'GET_DEVICE_ID';
export const GET_JITSIMEET = 'GET_JITSIMEET';
export const GET_MEDIA_DEVICES = 'GET_MEDIA_DEVICES';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_SERVICE_REGISTRATION = 'GET_SERVICE_REGISTRATION';
export const GET_HARMONY_API_URL = 'GET_HARMONY_API_URL';
export const GET_USER_TAB_ID = 'GET_USER_TAB_ID';
export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';
export const GET_CP_URL = 'GET_CP_URL';
export const GET_MS_ID = 'GET_MS_ID';
export const GET_HARMONY_ID = 'GET_HARMONY_ID';