import { API } from "../index"

export const SCHEDULE_ENDPOINTS = {
  checkroompassword(id='',data={}) {
    return API.Axios.meeting.v1.request({
      method: 'post',
      url: 'room/check-password',
      data,
      extra_data: {
        meeting_id: id,
      },
    })
  },
  getroomdetail(id){
    return API.Axios.meeting.v1.request({
      method: 'GET',
      url: `room/conf-id/${id}`,
      extra_data: {
        meeting_id: id,
      },
    })
  },
}