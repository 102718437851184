var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main2 dialer-login-flex new-bg new-bg-v2",class:{
    'dashboard-iframe':['dashboard','conference'].includes(_vm.screen)
  }},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100 vh-100"},[_c('div',{staticClass:"dialer-login",class:{
        'flex-column': _vm.$route.name=='signup',
        'inside_conference': _vm.$route.name=='conference' && _vm.screen=='callstartup',
      }},[_c('div',{staticClass:"dialer-login-inner d-flex flex-column",class:{
          'dialer-signup-form v2': _vm.$route.name=='signup' || _vm.$route.name=='invitation' || _vm.$route.name=='dashboard'
        }},[_c('b-alert',{staticClass:"w-100 position-fixed top-0 m-0 rounded-0 dismissibleAlert",staticStyle:{"z-index":"2000"},attrs:{"show":!_vm.isChrome,"variant":"info","dismissible":""}},[_vm._v(" Please use chrome browser for best experience. ")]),_c('div',[_c('router-view',{on:{"screen":function($event){_vm.screen=$event}}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }