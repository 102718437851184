/* eslint-disable no-dupe-class-members */
import { JITSI_LOGGER } from "@/service/index";
import Emitter from "component-emitter";
import { saveAs } from 'file-saver';
import { fabric } from "fabric";
import { brushes, modes, events, shapes, sync_data_event } from './constants'
import EVENTS from './events';
import { HELPER_FUNCTIONS } from "@/utils/functions";

class Whiteboard {
  // state===========
  // private---------
  #__canvas = null
  #patterens = {
    v_line: null,
    h_line: null,
    square: null,
    diamond: null,
  }
  #draw_instance = null
  #mouse_down = false
  #origin_x = 0
  #origin_y = 0
  // ----------------
  // public----------
  setting = {
    brush: brushes.PencilBrush.value,
    color: 'black',
    width: 10,
    shadow_width: 3,
    shadow_color: 'black',
    shadow_offset: 0,
    fill: false,
    fill_color: 'black',
  }
  shape = shapes.triangle
  call_backs = null
  mode = modes.pencil.value
  canvas_id = ''
  el_id = ''
  user_id = ''
  drawing_mode = true
  // ----------------
  
  // setters=========
  // private---------
  set #isDrawingMode(value){ 
    this.#__canvas.isDrawingMode = !!value;
    this.drawing_mode=!!value 
  }
  // ----------------
  // public----------
  set setShape(value){
    if(!shapes[value]) return;
    this.shape=value
  }
  // ----------------

  // getters=========
  // public----------
  get shapes(){ return shapes }
  get isEraseMode(){ return this.mode==modes.erase.value }
  get isPencilMode(){ return this.mode==modes.pencil.value }
  get isSelectMode(){ return this.mode==modes.select.value }
  get isLineMode(){ return this.mode==modes.line.value }
  get isShapesMode(){ return this.mode==modes.shapes.value }
  get modes(){ return modes }
  get brushes(){ return brushes }
  get isDrawingMode(){ return this.drawing_mode }
  // ----------------

  constructor(props){
    this.el_id=props?.el_id
    this.canvas_id=props?.canvas_id
    this.user_id=props?.user_id
    this.call_backs=props?.callBacks
    this.#__canvas = new fabric.Canvas(this.canvas_id, {
      isDrawingMode: this.drawing_mode,
      width: 800,
      height: 600,
    });
    const el = document.getElementById(this.el_id)
    const resize_ob = new ResizeObserver(this.#onResize.bind(this));
    resize_ob.observe(el)
    this.#onResize()
    fabric.Object.prototype.transparentCorners = false;
    this.#initPatterens()
    if (this.#__canvas.freeDrawingBrush) {
      this.#__canvas.freeDrawingBrush.color = this.setting.color;
      if(this.#__canvas.freeDrawingBrush.getPatternSrc) this.#__canvas.freeDrawingBrush.source = this.#__canvas.freeDrawingBrush.getPatternSrc.call(this);
      this.#__canvas.freeDrawingBrush.width = parseInt(this.setting.width, 10) || 1;
      this.#__canvas.freeDrawingBrush.shadow = new fabric.Shadow({
        blur: parseInt(this.setting.shadow_width, 10) || 0,
        offsetX: parseInt(this.setting.shadow_offset, 10) || 0,
        offsetY: parseInt(this.setting.shadow_offset, 10) || 0,
        affectStroke: true,
        color: this.setting.shadow_color,
      });
    }
    this.#__canvas.on(EVENTS.CANVAS.MOUSE.MOUSE_DOWN,this.#CANVAS_MOUSE_DOWN.bind(this))
    this.#__canvas.on(EVENTS.CANVAS.MOUSE.MOUSE_MOVE,this.#CANVAS_MOUSE_MOVE.bind(this))
    this.#__canvas.on(EVENTS.CANVAS.MOUSE.MOUSE_UP,this.#CANVAS_MOUSE_UP.bind(this))
    this.#__canvas.on(EVENTS.CANVAS.OBJECT.OBJECT_ADDED,this.#CANVAS_OBJECT_ADDED.bind(this));
    this.#__canvas.on(EVENTS.CANVAS.OBJECT.OBJECT_MODIFIED,this.#CANVAS_OBJECT_MODIFIED.bind(this));
    this.#__canvas.on(EVENTS.CANVAS.OBJECT.OBJECT_REMOVED,this.#CANVAS_OBJECT_REMOVED.bind(this));
    Emitter(this)
  }
  // function========
  // private---------
  #initPatterens(){
    if(!fabric.PatternBrush) return;
    // v_line
    this.#patterens.v_line = new fabric.PatternBrush(this.#__canvas)
    this.#patterens.v_line.getPatternSrc = function() {
      let patternCanvas = fabric.document.createElement('canvas');
      patternCanvas.width = patternCanvas.height = 10;
      let ctx = patternCanvas.getContext('2d');
      ctx.strokeStyle = this.color;
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(0, 5);
      ctx.lineTo(10, 5);
      ctx.closePath();
      ctx.stroke();
      return patternCanvas;
    };
    // ----
    // h_line
    this.#patterens.h_line = new fabric.PatternBrush(this.#__canvas)
    this.#patterens.h_line.getPatternSrc = function() {
      let patternCanvas = fabric.document.createElement('canvas');
      patternCanvas.width = patternCanvas.height = 10;
      let ctx = patternCanvas.getContext('2d');
      ctx.strokeStyle = this.color;
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(5, 0);
      ctx.lineTo(5, 10);
      ctx.closePath();
      ctx.stroke();
      return patternCanvas;
    };
    // ----
    // square
    this.#patterens.square = new fabric.PatternBrush(this.#__canvas)
    this.#patterens.square.getPatternSrc = function() {
      let squareWidth = 10, squareDistance = 2;
      let patternCanvas = fabric.document.createElement('canvas');
      patternCanvas.width = patternCanvas.height = squareWidth + squareDistance;
      let ctx = patternCanvas.getContext('2d');
      ctx.fillStyle = this.color;
      ctx.fillRect(0, 0, squareWidth, squareWidth);
      return patternCanvas;
    };
    // ----
    // diamond
    this.#patterens.diamond = new fabric.PatternBrush(this.#__canvas)
    this.#patterens.diamond.getPatternSrc = function() {
      let squareWidth = 10, squareDistance = 5;
      let patternCanvas = fabric.document.createElement('canvas');
      let rect = new fabric.Rect({
        width: squareWidth,
        height: squareWidth,
        angle: 45,
        fill: this.color
      });
      let canvasWidth = rect.getBoundingRect().width;
      patternCanvas.width = patternCanvas.height = canvasWidth + squareDistance;
      rect.set({ left: canvasWidth / 2, top: canvasWidth / 2 });
      let ctx = patternCanvas.getContext('2d');
      rect.render(ctx);
      return patternCanvas;
    };
    // ----
  }
  #sendData(type,event){
    JITSI_LOGGER.default1('SUYNC_DATA::SEND::',type,event)
    if(type==sync_data_event.object_added){
      if(event.target && !event.target?.user_id) event.target.set('user_id',this.user_id)
      if(event.target && !event.target?.id) event.target.set('id',`${this.user_id}${HELPER_FUNCTIONS.generateID()}`)
    } else if(type==sync_data_event.object_modified || type==sync_data_event.object_removed) {
      event.target.set('user_id',this.user_id)
    }
    if(event.target.user_id==this.user_id && event.target.id) {
      JITSI_LOGGER.default1('SUYNC_DATA::SEND::SENDED',event?.target?.toJSON?.(['id','user_id']),event.target.user_id,event.target.id,)
      this.#emmiting(events.syncdata,{
        event: type,
        data: JSON.stringify(event?.target?.toJSON?.(['id','user_id'])),
      })
    }
  }
  #emmiting(event={},payload={}){
    const evnt = event?.value
    const callBackName = event?.callBack
    if(evnt){
      const callBack = function (fun,data) { if(typeof fun == 'function') fun(data) }
      this.emit(evnt,payload)
      callBack(this.call_backs?.[callBackName],payload)
    }
  }
  // line draw ******
  #startAddLine(event){
    this.#mouse_down = true;
    let pointer = this.#__canvas.getPointer(event);
    this.#draw_instance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: this.setting.width,
      stroke: this.setting.color,
      selectable: false,
    });
    this.#__canvas.add(this.#draw_instance);
    this.#__canvas.requestRenderAll();
  }
  #startDrawingLine(event){
    if(this.#mouse_down){
      const pointer = this.#__canvas.getPointer(event);
      this.#draw_instance.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      this.#draw_instance.setCoords();
      this.#__canvas.requestRenderAll();
    }
  }
  #stopDrawing(){
    this.#mouse_down = false;
  }
  // trianle draw ***
  #startAddTriangle(event) {
    this.#mouse_down = true;
    const pointer = this.#__canvas.getPointer(event);
    this.#origin_x = pointer.x
    this.#origin_y = pointer.y
    this.#draw_instance = new fabric.Triangle({
      stroke: this.setting.color,
      strokeWidth: this.setting.width,
      fill: this.setting.fill ? this.setting.fill_color : 'transparent',
      left: pointer.x,
      top: pointer.y,
      width: 0,
      height: 0,
      selectable: false,
    });
    this.#__canvas.add(this.#draw_instance);
  }
  #startDrawingTriangle(event) {
    if (this.#mouse_down) {
      const pointer = this.#__canvas.getPointer(event);
      if (pointer.x < this.#origin_x) {
        this.#draw_instance.set('left', pointer.x);
      }
      if (pointer.y < this.#origin_y) {
        this.#draw_instance.set('top', pointer.y);
      }
      this.#draw_instance.set({
        width: Math.abs(pointer.x - this.#origin_x),
        height: Math.abs(pointer.y - this.#origin_y),
      });
      this.#draw_instance.setCoords();
      this.#__canvas.renderAll();
    }
  }
  // rect draw ***
  #startAddRect(event) {
    this.#mouse_down = true;

    const pointer = this.#__canvas.getPointer(event);
    this.#origin_x = pointer.x;
    this.#origin_y = pointer.y;

    this.#draw_instance = new fabric.Rect({
      stroke: this.setting.color,
      strokeWidth: this.setting.width,
      fill: this.setting.fill ? this.setting.fill_color : 'transparent',
      left: pointer.x,
      top: pointer.y,
      width: 0,
      height: 0,
      selectable: false,
    });

    this.#__canvas.add(this.#draw_instance);
  }
  #startDrawingRect(event) {
    if (this.#mouse_down) {
      const pointer = this.#__canvas.getPointer(event);

      if (pointer.x < this.#origin_x) {
        this.#draw_instance.set('left', pointer.x);
      }
      if (pointer.y < this.#origin_y) {
        this.#draw_instance.set('top', pointer.y);
      }
      this.#draw_instance.set({
        width: Math.abs(pointer.x - this.#origin_x),
        height: Math.abs(pointer.y - this.#origin_y),
      });
      this.#draw_instance.setCoords();
      this.#__canvas.renderAll();
    }
  }
  // ----------------
  // circle draw ***
  #startAddCircle(event) {
    this.#mouse_down = true;

    const pointer = this.#__canvas.getPointer(event);
    this.#origin_x = pointer.x;
    this.#origin_y = pointer.y;
    this.#draw_instance = new fabric.Ellipse({
      stroke: this.setting.color,
      strokeWidth: this.setting.width,
      fill: this.setting.fill ? this.setting.fill_color : 'transparent',
      left: pointer.x,
      top: pointer.y,
      cornerSize: 7,
      objectCaching: false,
      selectable: false,
    });
    this.#__canvas.add(this.#draw_instance);
  }
  #startDrawingCirle(event) {
    if (this.#mouse_down) {
      const pointer = this.#__canvas.getPointer(event);
      if (pointer.x < this.#origin_x) {
        this.#draw_instance.set('left', pointer.x);
      }
      if (pointer.y < this.#origin_y) {
        this.#draw_instance.set('top', pointer.y);
      }
      this.#draw_instance.set({
        rx: Math.abs(pointer.x - this.#origin_x) / 2,
        ry: Math.abs(pointer.y - this.#origin_y) / 2,
      });
      this.#draw_instance.setCoords();
      this.#__canvas.renderAll();
    }
  }
  // ----------------
  // public----------
  clear(){
    this.#__canvas.clear()
  }
  download(name='image'){
    const el = document.getElementById(this.canvas_id)
    if(!el) return;
    el.toBlob((blob)=>{
      saveAs(blob, `${name}.png`);
    })
  }
  setSetting(attribute='',value){
    if(attribute=='color'){
      if(!value && typeof value != 'string') return;
      const brush = this.#__canvas.freeDrawingBrush;
      brush.color = value;
      if (brush.getPatternSrc) {
        brush.source = brush.getPatternSrc.call(brush);
      }
      this.setting.color=value
    } else if (attribute=='shadow_color'){
      if(!value && typeof value != 'string') return;
      this.#__canvas.freeDrawingBrush.shadow.color = value
      this.setting.shadow_color=value
    } else if (attribute=='fill_color'){
      if(!value && typeof value != 'string') return;
      this.setting.fill_color=value
    } else if (attribute=='width'){
      if(typeof value != 'number' && value<0 && value>100) return;
      this.#__canvas.freeDrawingBrush.width = parseInt(value, 10) || 1;
      this.setting.width=value
    } else if (attribute=='shadow_width'){
      if(typeof value != 'number' && value<0 && value>100) return;
      this.#__canvas.freeDrawingBrush.shadow.blur = parseInt(value, 10) || 0;
      this.setting.shadow_width=value
    } else if (attribute=='shadow_offset'){
      if(typeof value != 'number' && value<0 && value>100) return;
      this.#__canvas.freeDrawingBrush.shadow.offsetX = parseInt(value, 10) || 0;
      this.#__canvas.freeDrawingBrush.shadow.offsetY = parseInt(value, 10) || 0;
      this.setting.shadow_offset=value
    } else if (attribute=='brush'){
      const selected_brush = this.brushes[value]
      if(!selected_brush) return;
      if(selected_brush.default){
        this.#__canvas.freeDrawingBrush = new fabric[selected_brush.value](this.#__canvas)
      } else {
        this.#__canvas.freeDrawingBrush = this.#patterens[selected_brush.value]
      }
      if (this.#__canvas.freeDrawingBrush) {
        const brush = this.#__canvas.freeDrawingBrush;
        brush.color = this.setting.color;
        if (brush.getPatternSrc) {
          brush.source = brush.getPatternSrc.call(brush);
        }
        brush.width = parseInt(this.setting.width, 10) || 1;
        brush.shadow = new fabric.Shadow({
          blur: parseInt(this.setting.shadow_width, 10) || 0,
          offsetX: parseInt(this.setting.shadow_offset, 10) || 0,
          offsetY: parseInt(this.setting.shadow_offset, 10) || 0,
          affectStroke: true,
          color: this.setting.shadow_color,
        });
      }
      this.setting.brush=selected_brush.value
    } else if (attribute=='fill'){
      this.setting.fill=!!value
    }
  }
  setMode(value){
    const mode = Object.values(modes).find(mode=>mode.value==value)
    if(!mode) return;
    this.mode = mode.value
    if([modes.select.value,modes.erase.value,modes.line.value,modes.shapes.value].includes(mode.value)){
      this.#isDrawingMode=false
    } else {
      this.#isDrawingMode=true
    }
    if([modes.line.value,modes.shapes.value].includes(mode.value)){
      this.#__canvas.selection = false;
      this.#__canvas.hoverCursor = 'auto';
      this.#__canvas.getObjects().map((item) => item.set({ selectable: false }));
      this.#__canvas.discardActiveObject().requestRenderAll();
    }
  }
  addText(){
    this.#isDrawingMode=false
    const text = new fabric.Textbox('text', {
      left: 100,
      top: 100,
      fill: this.setting.color,
      editable: true,
    });
    this.#__canvas.add(text);
    this.#__canvas.renderAll();
  }
  uploadImage(file){
    let that = this
    return new Promise((resolve,reject)=>{
      const reader = new FileReader();
      reader.onload = () => {
        fabric.Image.fromURL(reader.result, (img) => {
          img.scaleToHeight(that.#__canvas.height);
          that.#__canvas.add(img);
          resolve()
        });
      }
      reader.onerror = (ex) => {
        reject(ex)
      }
      reader.readAsDataURL(file);
    }) 
  }
  // ----------------
  
  // events==========
  // private---------
  #onResize(){
    const el = document.getElementById(this.el_id)
    // const ratio = this.#__canvas.getWidth() / this.#__canvas.getHeight();
    const width = el.clientWidth;
    const height = el.clientHeight;
    const scale = width / this.#__canvas.getWidth();
    const zoom = this.#__canvas.getZoom() * scale;
    const d_height = height < 100 ? 100 : height
    const d_width = width < 100 ? 100 : width
    this.#__canvas.setDimensions({ 
      width: d_width, 
      height: d_height
    });
    this.#__canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  }
  #CANVAS_MOUSE_DOWN(event){
    JITSI_LOGGER.event(`WHITEBOARD:EVENT:${EVENTS.CANVAS.MOUSE.MOUSE_DOWN}`,event)
    if(event.target && this.isEraseMode) this.#__canvas.remove(event.target);
    else if(this.isLineMode) this.#startAddLine(event)
    else if(this.isShapesMode){
      if(this.shape==shapes.triangle) this.#startAddTriangle(event)
      else if(this.shape==shapes.circle) this.#startAddCircle(event)
      else if(this.shape==shapes.square) this.#startAddRect(event)
    }
  }
  #CANVAS_MOUSE_MOVE(event){
    JITSI_LOGGER.event(`WHITEBOARD:EVENT:${EVENTS.CANVAS.MOUSE.MOUSE_MOVE}`,event)
    if (this.isLineMode) this.#startDrawingLine(event)
    else if(this.isShapesMode){
      if(this.shape==shapes.triangle) this.#startDrawingTriangle(event)
      else if(this.shape==shapes.circle) this.#startDrawingCirle(event)
      else if(this.shape==shapes.square) this.#startDrawingRect(event)
    }
  }
  #CANVAS_MOUSE_UP(event){
    JITSI_LOGGER.event(`WHITEBOARD:EVENT:${EVENTS.CANVAS.MOUSE.MOUSE_UP}`,event)
    if(this.isLineMode || this.isShapesMode) this.#stopDrawing()
  }
  #CANVAS_OBJECT_ADDED(event){
    JITSI_LOGGER.event(`WHITEBOARD:EVENT:${EVENTS.CANVAS.OBJECT.OBJECT_ADDED}`,event,event?.target?.toJSON?.())
    this.#sendData(sync_data_event?.object_added,event)
  }
  #CANVAS_OBJECT_MODIFIED(event){
    JITSI_LOGGER.event(`WHITEBOARD:EVENT:${EVENTS.CANVAS.OBJECT.OBJECT_MODIFIED}`,event)
    this.#sendData(sync_data_event?.object_modified,event)
  }
  #CANVAS_OBJECT_REMOVED(event){
    JITSI_LOGGER.event(`WHITEBOARD:EVENT:${EVENTS.CANVAS.OBJECT.OBJECT_REMOVED}`,event)
    this.#sendData(sync_data_event?.object_removed,event)
  }
  // ----------------
  // public----------
  recieveData(type='',json){
    let that = this
    JITSI_LOGGER.default2(`SUYNC_DATA::RECIEVE`,type,json)
    if(!(json instanceof Object) || typeof type != 'string' || json.user_id==this.user_id || !json.id) return;
    JITSI_LOGGER.default2(`SUYNC_DATA::RECIEVE::RECIEVED`)
    function getObject(id){
      if(!id) return;
      const objs = that.#__canvas.getObjects?.() ?? []
      return objs?.find?.(o=>o.id==id)
    }
    if(type==sync_data_event.object_added){
      fabric.util.enlivenObjects([json], function(objects) {
        objects.forEach((o) => {
          that.#__canvas.add(o);
        });
      });
    } else if(type==sync_data_event.object_modified){
      const obj = getObject(json.id)
      if(obj){
        obj._setObject(json)
        this.#__canvas.renderAll()
      }
    } else if(type==sync_data_event.object_removed){
      const obj = getObject(json.id)
      if(obj) that.#__canvas.remove(obj);
    }
  }
  // ----------------
}
export default Whiteboard
