// components
import Spinner from './components/Spinner.vue';
import Recaptcha from './components/Recaptcha.vue';
import PinCode from './components/PinCode.vue';
import Icon from './components/Icon.vue';

import { focus } from './directives/focus'
import { input_number_formate } from './directives/inputNumberFormate'
import { setSinkId } from './directives/setSinkId'
import { volume } from './directives/volume'

import { filter_date_current } from './filters/filterDatCurrent'
import { get_property } from './filters/getProperty'
import { apiInstance } from './global/apiInstance';
import { formInstance } from './global/formInstance';
import { duration_format } from './filters/durationFormat';
import { messageInstance } from './global/messageInstance';
export default {
  install(Vue){
    // instance
    Vue.prototype.$app = {
      api(...args){return apiInstance(...args) },
      form(...args){return formInstance(...args) },
      message(...args){return messageInstance(...args) },
    }
    // component
    Vue.component('app-spinner', Spinner)
    Vue.component('app-recaptcha', Recaptcha)
    Vue.component('app-pin-code', PinCode)
    Vue.component('app-icon', Icon)
    
    // filter
    Vue.filter('get_property',get_property)
    Vue.filter('filter_date_current',filter_date_current)
    Vue.filter('duration_format',duration_format)
    // mixin
    
    //directive
    Vue.directive('app-sink-id',setSinkId)
    Vue.directive('app-volume',volume)
    Vue.directive('app-focus',focus)
    Vue.directive('app-input-number-formate',input_number_formate)
  }
}