import { DIALER_LOGGER } from "../service/index";
import store from "@/store/index";
import { GET_USER_DIALER_SERVER } from "@/store/constants/getters";
var Emitter = require('component-emitter');

let events = {
  receive: {
    LOGOUT: 'LOGOUT',
    IFRAME_LOADED: 'IFRAME_LOADED',
    CHAT_DESKTOP_NOTIFICATION: 'CHAT_DESKTOP_NOTIFICATION',
    TEST_DESKTOP_NOTIFICATION: 'TEST_DESKTOP_NOTIFICATION',
    DESKTOP_NOTIFICATION: 'DESKTOP_NOTIFICATION',
    INCOMING_SIP_CALL_NOTIFICATION: 'INCOMING_SIP_CALL_NOTIFICATION',
    MISSED_SIP_CALL_NOTIFICATION: 'MISSED_SIP_CALL_NOTIFICATION',
    GET_DESKTOP_NOTIFICATION_PERMISSION: 'GET_DESKTOP_NOTIFICATION_PERMISSION',
    CHECK_DESKTOP_NOTIFICATION_PERMISSION: 'CHECK_DESKTOP_NOTIFICATION_PERMISSION',
    SWITCH_ORGANIZATION: 'SWITCH_ORGANIZATION',
    CALL_ENDED: 'CALL_ENDED',
    CALL_COMPLETED: 'CALL_COMPLETED',
    INCOMING_CALL: 'INCOMING_CALL',
    OUTGOING_CALL: 'OUTGOING_CALL',
    CALL_ANSWERED: 'CALL_ANSWERED',
  },
  send: {
    DIAL_NUMBER: 'DIAL_NUMBER',
    DESKTOP_PERMISSION_CHANGE: 'DESKTOP_PERMISSION_CHANGE',
    NOTIFICATION_CLICKED: 'NOTIFICATION_CLICKED',
    READY: 'READY',
    USER_DATA: 'USER_DATA',
  },
}
export const DIALER = {
  get events(){ return events },
  // callbacks
  onlogout: null,
  oniframeloaded: null,
  onchatdesktopnotification: null,
  ontestdesktopnotification: null,
  ondesktopnotification: null,
  onincomingsipcallnotification: null,
  onmissedsipcallnotification: null,
  ongetdesktopnotificationpermission: null,
  oncheckdesktopnotificationpermission: null,
  onswitchorganization: null,
  oncallended: null,
  oncallcompleted: null,
  onincomingcall: null,
  onoutgoingcall: null,
  oncallanswered: null,
  ongetloginresponse: null,
  // ----
  init(){
    try {
      Emitter(this)
      window.addEventListener('message',this.onWindowMessage.bind(this),false)
    } catch (error) {
      console.log('DIALER',error)
    } finally {
      if(process.env.NODE_ENV !== 'production'){
        window.voip_app.dialer = this
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  onWindowMessage(MessageEvent){
    const server = store.getters[GET_USER_DIALER_SERVER]
    if (MessageEvent.origin==server && typeof MessageEvent?.data == 'string'){
      console.log('MessageEvent?.data',MessageEvent?.data)
      const data = JSON.parse(MessageEvent?.data ?? `{}`)
      const event = data?.event ?? '';
      const payload = data?.payload ?? {};
      DIALER_LOGGER.event('Message From Dialer',data)
      switch (event) {
        case this.events.receive.LOGOUT:
          this.emit?.(this.events.receive.LOGOUT,payload)
          this.onlogout?.(payload)
          break;
        case this.events.receive.IFRAME_LOADED:
          this.emit?.(this.events.receive.IFRAME_LOADED,payload)
          this.oniframeloaded?.(payload)
          this.sendData(this.events.send.READY)
          break;
        case this.events.receive.CHAT_DESKTOP_NOTIFICATION:
          this.emit?.(this.events.receive.CHAT_DESKTOP_NOTIFICATION,payload)
          this.onchatdesktopnotification?.(payload)
          break;
        case this.events.receive.TEST_DESKTOP_NOTIFICATION:
          this.emit?.(this.events.receive.TEST_DESKTOP_NOTIFICATION,payload)
          this.ontestdesktopnotification?.(payload)
          break;
        case this.events.receive.DESKTOP_NOTIFICATION:
          this.emit?.(this.events.receive.DESKTOP_NOTIFICATION,payload)
          this.ondesktopnotification?.(payload)
          break;
        case this.events.receive.INCOMING_SIP_CALL_NOTIFICATION:
          this.emit?.(this.events.receive.INCOMING_SIP_CALL_NOTIFICATION,payload)
          this.onincomingsipcallnotification?.(payload)
          break;
        case this.events.receive.MISSED_SIP_CALL_NOTIFICATION:
          this.emit?.(this.events.receive.MISSED_SIP_CALL_NOTIFICATION,payload)
          this.onmissedsipcallnotification?.(payload)
          break;
        case this.events.receive.GET_DESKTOP_NOTIFICATION_PERMISSION:
          this.emit?.(this.events.receive.GET_DESKTOP_NOTIFICATION_PERMISSION,payload)
          this.ongetdesktopnotificationpermission?.(payload) 
          break;
        case this.events.receive.CHECK_DESKTOP_NOTIFICATION_PERMISSION:
          this.emit?.(this.events.receive.CHECK_DESKTOP_NOTIFICATION_PERMISSION,payload)
          this.oncheckdesktopnotificationpermission?.(payload) 
          break;
        case this.events.receive.SWITCH_ORGANIZATION:
          this.emit?.(this.events.receive.SWITCH_ORGANIZATION,payload)
          this.onswitchorganization?.(payload)
          break;
        case this.events.receive.CALL_ENDED:
          this.emit?.(this.events.receive.CALL_ENDED,payload)
          this.oncallended?.(payload)
          break;
        case this.events.receive.CALL_COMPLETED:
          this.emit?.(this.events.receive.CALL_COMPLETED,payload)
          this.oncallcompleted?.(payload)
          break;
        case this.events.receive.INCOMING_CALL:
          this.emit?.(this.events.receive.INCOMING_CALL,payload)
          this.onincomingcall?.(payload)
          break;
        case this.events.receive.OUTGOING_CALL:
          this.emit?.(this.events.receive.OUTGOING_CALL,payload)
          this.onoutgoingcall?.(payload)
          break;
        case this.events.receive.CALL_ANSWERED:
          this.emit?.(this.events.receive.CALL_ANSWERED,payload)
          this.oncallanswered?.(payload)
          break;
        default:
          break;
      }
    }
  },
  sendData(event,payload={}){
    if(!this.events.send[event]) return;
    const data = { event, payload, }
    document.getElementById('dailer-frame')?.contentWindow?.postMessage(JSON.stringify(data), '*');
  },
}