export const shortcut_key_events = {
  toggle_audio_mute: 'toggle_audio_mute',
  toggle_video_mute: 'toggle_video_mute',
  toggle_hold: 'toggle_hold',
  toogle_hand_rise: 'toogle_hand_rise',
  toogle_chat: 'toogle_chat',
  toogle_participants: 'toogle_participants',
  toogle_full_screen: 'toogle_full_screen',
  copy_link: 'copy_link',
  hang_up: 'hang_up',
  open_setting: 'open_setting',
  open_shortcuts: 'open_shortcuts',
  grid_view: 'grid_view',
  speaker_view: 'speaker_view',
  performance_setting: 'performance_setting',
  recording: 'recording',
  toogle_white_board: 'toogle_white_board',
}
export const shortcutkeys = [
  {
    title: 'ShortCut Keys Modal',
    shortcuts: ['Ctrl','/'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && !event.shiftKey && event.code=='Slash' },
    event: shortcut_key_events.open_shortcuts,
  },
  {
    title: 'Hangup',
    shortcuts: ['Ctrl','H'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyH' },
    event: shortcut_key_events.hang_up,
  },
  {
    title: 'audio: mute/unmute',
    shortcuts: ['Ctrl','M'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyM' },
    event: shortcut_key_events.toggle_audio_mute,
  },
  {
    title: 'video: mute/unmute',
    shortcuts: ['Ctrl', 'Shift', 'V'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && event.shiftKey && event.code=='KeyV' },
    event: shortcut_key_events.toggle_video_mute,
  },
  {
    title: 'hold/unhold',
    shortcuts: ['Ctrl', 'Shift', 'H'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && event.shiftKey && event.code=='KeyH' },
    event: shortcut_key_events.toggle_hold,
  },
  {
    title: 'hand rise',
    shortcuts: ['Ctrl', 'Alt', 'H'],
    isShortcut(event){ return event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyH' },
    event: shortcut_key_events.toogle_hand_rise,
  },
  {
    title: 'fullscreen/exit fullscreen',
    shortcuts: ['Ctrl', 'F'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyF' },
    event: shortcut_key_events.toogle_full_screen,
  },
  {
    title: 'open setting',
    shortcuts: ['Ctrl', 'S'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyS' },
    event: shortcut_key_events.open_setting,
  },
  {
    title: 'copy link',
    shortcuts: ['Ctrl', 'Shift', 'C'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && event.shiftKey && event.code=='KeyC' },
    event: shortcut_key_events.copy_link,
  },
  {
    title: 'chat: open/close',
    shortcuts: ['Ctrl', 'Alt', 'C'],
    isShortcut(event){ return event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyC' },
    event: shortcut_key_events.toogle_chat,
  },
  {
    title: 'participants: open/close',
    shortcuts: ['Ctrl', 'P'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyP' },
    event: shortcut_key_events.toogle_participants,
  },
  {
    title: 'Speaker View',
    shortcuts: ['Ctrl', 'Shift', 'S'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && event.shiftKey && event.code=='KeyS' },
    event: shortcut_key_events.speaker_view,
  },
  {
    title: 'Grid View',
    shortcuts: ['Ctrl', 'Shift', 'G'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && event.shiftKey && event.code=='KeyG' },
    event: shortcut_key_events.grid_view,
  },
  {
    title: 'Performance Setting',
    shortcuts: ['Ctrl', 'Shift', 'P'],
    isShortcut(event){ return !event.altKey && event.ctrlKey && event.shiftKey && event.code=='KeyP' },
    event: shortcut_key_events.performance_setting,
  },
  {
    title: 'Recording',
    shortcuts: ['Ctrl', 'Alt', 'R'],
    isShortcut(event){ return event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyR' },
    event: shortcut_key_events.recording,
  },
  {
    title: 'Open White Board',
    shortcuts: ['Ctrl', 'Alt', 'W'],
    isShortcut(event){ return event.altKey && event.ctrlKey && !event.shiftKey && event.code=='KeyW' },
    event: shortcut_key_events.toogle_white_board,
  },
]