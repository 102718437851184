import { storage } from '@/service/store.service';
import createPersistedState from 'vuex-persistedstate';


export default createPersistedState({
  key: storage.SYSTEM.key,
  paths: [
    'system.cp_domain',
    'system.cp_url',
    'system.mothership_id',
    'system.harmony_id',
  ],
  storage: {
    getItem: (key) => key==storage.SYSTEM.key ? storage.SYSTEM.get() : '',
    setItem: (key, value) => key==storage.SYSTEM.key ? storage.SYSTEM.set(value) : '',
    removeItem: (key) => key==storage.SYSTEM.key ? storage.SYSTEM.remove() : '',
  }
});