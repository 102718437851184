import { DEVICE_INFO } from "@/utils/index";
import { BROADCAST_LOGGER } from "@/service/index";
import { BROADCAST_EVENTS } from "./events";

export const BROADCAST = {
  broadcastChannel: null,
  site_text: 'voipbusiness-app',
  get events(){ return BROADCAST_EVENTS },
  active_tab_id: '',
  onnotactivetab: null,
  init(){
    try {
      BROADCAST_LOGGER.log('run')
      this.broadcastChannel = new BroadcastChannel(this.site_text);
      this.broadcastChannel.onmessage = this.onMessage.bind(this)
      this.sendMessage(this.events.INITIALIZE,{
        tab_id: DEVICE_INFO.tab_id
      });
    } catch (error) {
      console.log('BROADCAST',error)
    } finally {
      if(process.env.NODE_ENV!='production'){
        window.voip_app.BROADCAST = this
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  makeActiveTab(){
    this.sendMessage(this.events.ACTIVE_TAB,{
      tab_id: DEVICE_INFO.tab_id
    });
  },
  checkTabExits(tab_id){
    let that = this
    return new Promise((resolve,reject)=>{
      this.sendMessage(this.events.CHECK_TAB_EXITS,{
        tab_id: tab_id
      });
      setTimeout(()=>{
        that.active_tab_id==tab_id ? resolve() : reject()
      },2*1000)
    })
  },
  onMessage(Event){
    const { event, payload } = JSON.parse(Event.data)
    BROADCAST_LOGGER.event(event,payload)
    if(event==this.events.CHECK_TAB_EXITS) {
      const { tab_id } = payload
      if(tab_id==DEVICE_INFO.tab_id){
        this.sendMessage(this.events.ACTIVE_TAB,{
          tab_id: DEVICE_INFO.tab_id
        });
      }
    } else if(event==this.events.ACTIVE_TAB) {
      const { tab_id } = payload
      this.active_tab_id=tab_id
      if(this.active_tab_id!=DEVICE_INFO.tab_id) {
        this.onnotactivetab?.()
      }
    }
  },
  sendMessage(event,payload={}){
    if(this.events[event]){
      this.broadcastChannel.postMessage(JSON.stringify({
        event,
        payload,
      }));
    }
  },
}