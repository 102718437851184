import Vue from 'vue';
import Vuex from 'vuex';

import authPlugin from './plugins/auth'
import authModule from './modules/auth'

import appPlugin from './plugins/app'
import appModule from './modules/app'

import systemPlugin from './plugins/system'
import systemModule from './modules/system'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authModule,
    app: appModule,
    system: systemModule,
  },
  plugins: [
    authPlugin,
    appPlugin,
    systemPlugin,
  ],
});