import Tracker from '@openreplay/tracker'

export const OPEN_REPLAY = {
  tracker: null,
  interval: null,
  init(){
    this.tracker = new Tracker({
      projectKey: 'Rg1IuVjXFPbbaaqlOHrc',
      ingestPoint: "https://openreplay.duplex.network/ingest",
      defaultInputMode: 0,
      obscureTextNumbers: false,
      obscureTextEmails: false,
      obscureInputEmails: false,
      obscureInputDates: false,
      __DISABLE_SECURE_MODE: process.env.NODE_ENV!='production',
    })
  },
  async start(){
    try {
      if(this.interval!=null) clearInterval(this.interval)
      console.log('session is active',this.tracker.isActive())
      if(this.tracker.isActive()) this.tracker.stop()
      const startedSession = await this.tracker.start({
        forceNew: true,
        metadata: {
          type: 'signup'
        },
      })
      console.log('startedSession',startedSession)
      if(!startedSession.success) throw new Error('failed')
      console.log('session started',startedSession)
    } catch (error) {
      console.log('session start failed',error)
    }
  },
  async stop(){
    let that = this
    try {
      if(this.interval!=null) clearInterval(this.interval)
      console.log('session is active',this.tracker.isActive())
      const hash = this.tracker.stop()
      console.log('session stoped',hash)
      console.log('session is active',this.tracker.isActive())
      let x = 0;
      this.interval = setInterval(()=>{
        console.log('session is active',that.tracker.isActive())
        if(that.tracker.isActive()) that.tracker.stop()
        if (++x==30) clearInterval(that.interval);
      },1*1000)
    } catch(error) {
      console.log('session stop failed',error)
    }
  },
}