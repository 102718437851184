/* eslint-disable no-dupe-class-members */
import Emitter from 'component-emitter'
import { JITSI_LOGGER } from '../../service/index';
import { HELPER_FUNCTIONS } from '../../utils/functions';
import RunningCall from './runningcall';
import { shortcutkeys } from './shortcuts'
const Jitsimeet = {
  runningcalls: [],
  activecallid: '',
  get activecall(){ return this.runningcalls[this.runningcalls.findIndex(item=>item.id==this.activecallid)] },
  init(){
    try {
      Emitter(this)
      window.JitsiMeetJS?.init({
        useIPv6: true,
        disableAudioLevels: true,
        disableSimulcast: true,
        enableWindowOnErrorHandler: false,
        disableThirdPartyRequests: true,
        enableAnalyticsLogging: false,
        externalStorage: null,
        callStatsCustomScriptUrl: "https://jitsi.kone.duplex.network/",
        disableRtx: false,
        disableH264: false,
        preferH264: true,
      });
      window.JitsiMeetJS.test.setLogLevel(window.JitsiMeetJS.logLevels.ERROR)
      document.addEventListener('keydown',this.onKeyDown.bind(this))
    } catch (error) {
      console.log('Jitsimeet',error)
    } finally {
      if(process.env.NODE_ENV!='production'){
        window.voip_app.jitsimeet = this
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  onKeyDown(event){
    // const { ctrlKey, shiftKey, code } = event
    // JITSI_LOGGER.log('ctrlKey, shiftKey, code',ctrlKey, shiftKey, code, event)
    const callBack = function (fun,data) { if(typeof fun == 'function') fun(data) }
    if(!this.activecall && !this.activecall?.is_join) return;
    const shortcut = shortcutkeys.find(v=>v.isShortcut(event))
    if(shortcut){
      event.preventDefault()
      callBack(this.activecall?.onshortcutkeypress,shortcut.event)
    } 
  },
  joinconference(props){
    const {
      url='',
      meeting_id,
      token,
      call_type='video:audio',
      conference_name,
      phone_number,
    } = props
    JITSI_LOGGER.log('url',url)
    const id  = HELPER_FUNCTIONS.generateID()
    this.holdallcalls()
    const runningcall = new RunningCall({
      url,
      id,
      meeting_id,
      callType: `conference:${call_type.includes('video')?'video:':''}:${call_type.includes('audio')?'audio:':''}`,
      token,
      conference:{
        name: conference_name,
        phone_number: phone_number,
      },
    })
    runningcall.on(runningcall.events.distroy,this.runningcalldistroy.bind(this))
    runningcall.on(runningcall.events.unhold,this.runningcallunhold.bind(this))
    this.runningcalls.push(runningcall)
    this.activecallid=id
  },
  holdallcalls(){
    Promise.all(this.runningcalls.map(call=>call.hold()))
  },
  runningcalldistroy({ id }){
    JITSI_LOGGER.log('distroy',this.runningcalls.findIndex(item=>item.id==id))
    this.runningcalls.splice(this.runningcalls.findIndex(item=>item.id==id),1)
    if(this.activecallid==id){
      this.activecallid=''
    }
    JITSI_LOGGER.log(this.activecallid,this.runningcalls)
  },
  runningcallunhold({ id }){
    this.activecallid=id
  },
}
export default Jitsimeet