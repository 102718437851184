import { AsYouType } from "libphonenumber-js"
export const input_number_formate = {
  oninput(event){
    const { country, /*national_format,*/ as_you_type, auto_start_plus } = this.def
    let value = event.target.value
    const prev_cursor_position = event.target.selectionStart
    if(!country && auto_start_plus) value = event.target.value?.startsWith?.('+') ? event.target.value : `+${event.target.value}`
    let formatted = value;
    if(as_you_type){
      formatted = as_you_type.input(value)
      if(as_you_type.country && as_you_type.defaultCountry!=as_you_type.country) formatted=value
    }
    event.target.value = formatted
    const after_cursor_position = event.target.selectionStart
    if(after_cursor_position-1==prev_cursor_position) event.target.setSelectionRange(after_cursor_position, after_cursor_position);
    else event.target.setSelectionRange(prev_cursor_position, prev_cursor_position);
  },
  setCountry(country){
    this.def.country=country
    this.def.as_you_type= new AsYouType(country || '')
  },
  inserted(el,binding){
    const { national, start_plus } = binding?.modifiers ?? {}
    binding.def.national_format=!!national
    if(typeof start_plus != 'undefined') binding.def.auto_start_plus=!!start_plus
    binding.def.setCountry.call(binding,binding.value)
  },
  bind(el,binding){
    el.addEventListener('input',binding.def.oninput.bind(binding))
  },
  update(el,binding){
    binding.def.setCountry.call(binding,binding.value)
  },
  unbind(el,binding){
    el.removeEventListener('input',binding.def.oninput.bind(binding))
  },
}