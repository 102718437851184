export const brushes = {
  // v_line: {
  //   text: 'Vertical Line',
  //   default: false,
  //   value: 'v_line',
  // },
  // h_line: {
  //   text: 'Horizontal Line',
  //   default: false,
  //   value: 'h_line',
  // },
  // square: {
  //   text: 'Square',
  //   default: false,
  //   value: 'square',
  // },
  // diamond: {
  //   text: 'Diamond',
  //   default: false,
  //   value: 'diamond',
  // },
  PencilBrush: {
    text: 'Pencil',
    default: true,
    value: 'PencilBrush',
  },
  CircleBrush: {
    text: 'Circle',
    default: true,
    value: 'CircleBrush',
  },
  SprayBrush: {
    text: 'Spray',
    default: true,
    value: 'SprayBrush',
  },
  PatternBrush: {
    text: 'Pattern',
    default: true,
    value: 'PatternBrush',
  },
}
export const modes = {
  erase: {
    text: 'Erase',
    value: 'erase'
  },
  pencil: {
    text: 'Pencil',
    value: 'pencil'
  },
  shapes: {
    text: 'Shapes',
    value: 'shapes'
  },
  line: {
    text: 'Line',
    value: 'line'
  },
  select: {
    text: 'Select',
    value: 'select'
  },
}
export const events = {
  objectmodified: {
    value: 'objectmodified',
    callBack: 'onObjectModified',
    callBackFunction: 'onobjectmodified',
  },
  objectadded: {
    value: 'objectadded',
    callBack: 'onObjectAdded',
    callBackFunction: 'onobjectadded',
  },
  objectmoving: {
    value: 'objectmoving',
    callBack: 'onObjectMoving',
    callBackFunction: 'onobjectmoving',
  },
  objectremoved: {
    value: 'objectremoved',
    callBack: 'onObjectRemoved',
    callBackFunction: 'onobjectremoved',
  },
  pathcreated: {
    value: 'pathcreated',
    callBack: 'onPathCreated',
    callBackFunction: 'onpathcreated',
  },
  syncdata: {
    value: 'syncdata',
    callBack: 'onSyncData',
    callBackFunction: 'onsyncdata',
  },
}
export const shapes = {
  triangle: 'triangle',
  circle: 'circle',
  square: 'square',
}
export const sync_data_event = {
  object_added: 'object_added',
  object_modified: 'object_modified',
  object_removed: 'object_removed',
}