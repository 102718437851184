import { ROUTER_LOGGER } from '@/service/index';
import store from '@/store/index';
import { GET_IS_LOGGED_IN } from '@/store/constants/getters';

export function beforeEach(to, from, next) {
	const notRequiresAuth = to.matched.some((record) => record.meta.notRequiresAuth)
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
	const logged_in = store.getters[GET_IS_LOGGED_IN]
  ROUTER_LOGGER.log(to,to.name,notRequiresAuth,logged_in,requiresAuth)
	if(notRequiresAuth && logged_in) {
    next({
			name: 'dashboard'
		});
  } else if(requiresAuth && !logged_in) {
    next({
			name: 'login'
		});
  } else if(to.name==null) {
    next({
			name: 'login'
		});
  } else {
    next();
  }
}