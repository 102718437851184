export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_CP_DOMAIN = 'SET_CP_DOMAIN';
export const SET_CP_DEVICE_INFO = 'SET_CP_DEVICE_INFO';
export const SET_JITSIMEET = 'SET_JITSIMEET';
export const SET_MEDIA_DEVICES = 'SET_MEDIA_DEVICES';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_SERVICE_REGISTRATION = 'SET_SERVICE_REGISTRATION';
export const SET_DEVICE_INFO = 'SET_DEVICE_INFO';
export const SET_CP_URL = 'SET_CP_URL';
export const SET_MS_ID = 'SET_MS_ID';
export const SET_HARMONY_ID = 'SET_HARMONY_ID';