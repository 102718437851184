import { storage } from '@/service/store.service';
import createPersistedState from 'vuex-persistedstate';

console.log(storage)
export default createPersistedState({
  key: storage.AUTH.key,
  paths: [
    `auth.token`,
  ],
  storage: {
    getItem: (key) => key==storage.AUTH.key ? storage.AUTH.get() : '',
    setItem: (key, value) => key==storage.AUTH.key ? storage.AUTH.set(value) : '',
    removeItem: (key) => key==storage.AUTH.key ? storage.AUTH.remove() : '',
  }
});