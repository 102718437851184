import Vue from 'vue'


// moment init--------------------------------
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);
// -------------------------------------------

// validation purpose-------------------------
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
// -------------------------------------------

// bootstrap ---------------------------------
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// -------------------------------------------

// vue-js-modal ------------------------------
import VModal from "vue-js-modal";
Vue.use(VModal);
// -------------------------------------------

// vue-toaster -------------------------------
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast)
// -------------------------------------------

// APP ---------------------------------------
import APP from './bootstrap/index'
Vue.use(APP)
// -------------------------------------------

// router ------------------------------------
import router from './router/index'
// -------------------------------------------

// store -------------------------------------
import store from './store/index'
// -------------------------------------------
import App from './App.vue'
import './registerServiceWorker'
Vue.config.productionTip = false
new Vue({
  name: 'dialer-login',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
