export function messageInstance(){
  let obj = {
    message: '',
    success: false,
  }
  const reset = function(){
    obj.message=''
    obj.success=false
  }
  obj.setSuccessMessage = function(message) {
    obj.message=message
    obj.success=true
  }
  obj.setErrorMessage = function(message) {
    obj.message=message
    obj.success=false
  }
  obj.reset = reset.bind(obj)
  return obj
}