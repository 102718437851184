/* eslint-disable no-unused-vars */
import { OPEN_REPLAY } from "@/openreplay/index";
import { routes_names } from "../constants/routes-names"

export function afterEach(to, from, next) {
  const setup_flag = process.env.VUE_APP_SETUP_FLAG
  if(setup_flag=='production') {
    if(from.name==routes_names.signup) {
      OPEN_REPLAY.stop()
      document.getElementById('hs-script-loader')?.remove?.();
    }
    if(to.name==routes_names.signup) {
      OPEN_REPLAY.start()
      const hs_script = document.createElement('script');
      hs_script.type = 'text/javascript';
      hs_script.src = '//js.hs-scripts.com/6005544.js';
      hs_script.id='hs-script-loader'
      hs_script.async=true
      hs_script.defer=true
      document.body.appendChild(hs_script);
    }
  }
}