const onError = (e) => {
  if (e.name !== 'NavigationDuplicated') throw e
}
export function errorHandling(router){
  const _push = router.__proto__.push
  router.__proto__.push = function push (...args) {
    try {
      const op = _push.call(this, ...args)
      if (op instanceof Promise) op.catch(onError)
      return op
    } catch (e) {   
      onError(e)
    }
  }
}