import SecureLS from "secure-ls";
const ls = new SecureLS({ 
  encodingType: 'rc4',
  isCompression: true,
  encryptionSecret: '@#$@#$@^#@$%'
});
class StorageStore {
  #key = ''
  #encrypted = false
  get key(){ return this.#key }
  #callBacks = {
    onSet: null,
    onRemove: null,
  }
  onset = null;
  onremove = null;
  constructor(key,options={}){
    this.#key = key
    this.#encrypted = !!options.encrypted
    this.#callBacks.onSet=options?.onSet ?? null
    this.#callBacks.onRemove=options?.onRemove ?? null
  }
  set(data,cb){
    let payload = JSON.stringify(data ?? '')
    if(this.#encrypted){
      ls.set(this.#key,payload)
    } else {
      localStorage.setItem(this.#key, payload)  
    }
    this.#callBacks.onSet?.(data)
    this.onset?.(data)
    cb?.(data)
  }
  get(){
    try {
      let res;
      if(this.#encrypted){
        res = ls.get(this.#key)
      } else {
        res = localStorage.getItem(this.#key)
      }
      return JSON.parse(res || null)
    } catch {
      return null
    }
  }
  remove(){
    if(this.#encrypted){
      ls.remove(this.#key)
    } else {
      localStorage.removeItem(this.#key)
    }
    this.#callBacks.onRemove?.()
    this.onremove?.()
  }
}
export const storage = {
  AUTH: new StorageStore('AUTH',{
    encrypted: true,
  }),
  APP: new StorageStore('APP',{
    encrypted: true,
  }),
  SYSTEM: new StorageStore('SYSTEM',{
    encrypted: true,
  }),
}