<template>
  <div>
    <div ref="recaptcha"></div>
    <!-- <app-spinner color="black" v-if="loading" /> -->
    <div v-if="loading" class="latestShimmerDesign" style="width: 304px; height: 78px;"></div>
  </div>
</template>

<script>
var interval_instance;
export default {
  name: "AppRecaptcha",
  data() {
    return {
      recaptcha: null,
      loading: false,
    }
  },
  props: {
    siteKey: {
      type: String,
      default: process.env.VUE_APP_RECAPTCHA_V2_SITE_KEY ?? '',
    },
    size: {
      type: String,
      required: false,
      default: "normal"
    },
    theme: {
      type: String,
      required: false,
      default: "light"
    },
    hl: {
      type: String,
      required: false,
      default: 'en',
    }
  },
  emits: {
    verify: (response) => !!response,
    expire: null,
    fail: null
  },
  methods: {
    renderRecaptcha() {
      try {
        this.recaptcha = window.grecaptcha.render(this.$refs.recaptcha, {
          'sitekey': this.siteKey,
          'theme': this.theme,
          'size': this.size,
          'callback': (response) => this.$emit("verify", response),
          'expired-callback': () => this.$emit("expire"),
          'error-callback': () => this.$emit("fail")
        });
      } catch {
        this.$emit("verify", true)
      }
    },
    execute() {
      window.grecaptcha.execute(this.recaptcha);
    },
    reset() {
      window.grecaptcha.reset(this.recaptcha);
    }
  },
  mounted() {
    let vm = this
    this.$emit('load')
    if (window.grecaptcha == null) {
      vm.loading=true
      new Promise((resolve,reject) => {
        window.recaptchaReady = function () {
          resolve();
        };
        const doc = window.document;
        const scriptId = "recaptcha-script";
        const scriptTag = doc.createElement("script");
        scriptTag.id = scriptId;
        scriptTag.setAttribute("src", `https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit&hl=${vm.hl}`);
        doc.head.appendChild(scriptTag);
        let check_try = 0;
        interval_instance = setInterval(()=>{
          check_try=check_try+1
          if(window.grecaptcha){
            resolve();
          }
          if(check_try==5){
            reject()
          }
        }, 4 * 1000);
      })
      .then(() => {
        vm.renderRecaptcha();
      })
      .catch(()=>{
        vm.$emit("verify", true)
      })
      .finally(()=>{
        vm.loading=false
        clearInterval(interval_instance)
      });
    }
    else {
      vm.renderRecaptcha();
    }
  },
  beforeDestroy(){
    clearInterval(interval_instance)
  },
}
</script>