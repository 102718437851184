
var Emitter = require('component-emitter');
const events = {
  change_camera_permission: 'change_hamera_permission',
  change_mic_permission: 'change_mic_permission',
  change_notification_permission: 'change_notification_permission',
}
export const PERMISSIONS = {
  mic: '',
  notification: '',
  camera: '',
  get events(){ return events },
  onchangenotification: null,
  onchangemic: null,
  onchangecamera: null,
  init(){
    try {
      Emitter(this)
      this.getmicrophone()
      this.getnotification()
      this.getcamera()
    } catch (error) {
      console.log('PERMISSIONS',error)
    } finally {
      if(process.env.NODE_ENV!='production'){
        window.voip_app.PERMISSIONS = this
      }
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  getmicrophone(){
    let that = this
    navigator?.permissions?.query?.({
      name: "microphone",
    }).then((permission)=>{
      that.mic=permission.state
      permission.onchange = that.onmicrophonechange.bind(that);
    });
  },
  onmicrophonechange(Event){
    this.mic = Event.currentTarget.state;
    this.emit(events.change_mic_permission,this.mic)
    this.onchangemic?.(this.mic)
  },
  async getMicPermission(){
    try {
      if(this.mic=='denied') throw 'permissions is blocked'
      if(this.mic=='granted') throw 'already have permission'
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      stream.getTracks().forEach((track) => track.stop());
    } catch (ex) {
      throw new Error(ex.message)
    }
  },
  getnotification(){
    let that = this
    navigator?.permissions?.query?.({
      name: "notifications",
    }).then((permission)=>{
      that.notification=permission.state
      permission.onchange = that.onnotificationchange.bind(that);
    });
  },
  onnotificationchange(Event){
    this.notification = Event.currentTarget.state;
    this.emit(events.change_notification_permission,this.notification)
    this.onchangenotification?.(this.notification)
  },
  getNotificationPermission(){
    try {
      if(this.notification=='denied') throw 'permissions is blocked'
      if(this.notification=='granted') throw 'already have permission'
      if("Notification" in window) throw 'notification object are not in window'
      Notification.requestPermission();
    } catch (ex) {
      throw new Error(ex.message)
    }
  },
  getcamera(){
    let that = this
    navigator?.permissions?.query?.({
      name: "camera",
    }).then((permission)=>{
      that.camera=permission.state
      permission.onchange = that.oncamerachange.bind(that);
    });
  },
  oncamerachange(Event){
    this.camera = Event.currentTarget.state;
    this.emit(events.change_camera_permission,this.camera)
    this.onchangecamera?.(this.camera)
  },
  async getCameraPermission(){
    try {
      if(this.camera=='denied') throw 'permissions is blocked'
      if(this.camera=='granted') throw 'already have permission'
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      })
      stream.getTracks().forEach((track) => track.stop());
    } catch (ex) {
      throw new Error(ex.message)
    }
  },
}